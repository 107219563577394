import React, { ComponentProps, useMemo } from 'react';

import LogoVertical from 'icons/full-logo.svg';
import LogoHorizontal from 'icons/full-logo-horizontal.svg';
import LogoSymbol from 'icons/logo-symbol.svg';
import { cx } from 'utils';
import styles from './Logo.module.css';

type Variant = 'vertical' | 'horizontal' | 'symbol';

export type LogoProps = {
  variant?: Variant;
} & Omit<ComponentProps<'div'>, 'alt' | 'src'>;

const Logo: React.FC<LogoProps> = ({ className, variant, ...props }) => {
  const Component = useMemo<string>(() => {
    switch (variant) {
      case 'horizontal':
        return LogoHorizontal;
      case 'symbol':
        return LogoSymbol;
      // Fallthrough for clarity
      case 'vertical':
      default:
        return LogoVertical;
    }
  }, [variant]);

  return (
    <div className={cx(styles.logo, className)} aria-label="Arkivet logotyp" {...props}>
      <Component />
    </div>
  );
};

export default Logo;
