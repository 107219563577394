import React from 'react';

import Typography from 'components/ui/Typography';
import OrArray from 'types/OrArray';
import { cx } from 'utils';
import styles from './DrawerHeader.module.css';

interface DrawerHeaderProps {
  children: OrArray<React.ReactNode>;
  className?: string;
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ children, ...props }) => {
  return <div className={cx(styles.root, props.className)}>{children}</div>;
};

export const DrawerHeaderTitle = ({ children }: { children: OrArray<React.ReactNode> }) => (
  <div className={styles.headerTitle}>
    <Typography component="p" align="center" variant="b">
      {children}
    </Typography>
  </div>
);

export default DrawerHeader;
