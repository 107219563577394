import React from 'react';

const CookieContext = React.createContext<{
  accepted: boolean;
  analytics: boolean;
  marketing: boolean;
}>({
  accepted: false,
  analytics: false,
  marketing: false,
});

export type CookieProviderProps = {
  accepted: boolean;
  analytics: boolean;
  children: React.ReactNode;
  marketing: boolean;
};

const CookieProvider: React.FC<CookieProviderProps> = props => {
  return <CookieContext.Provider value={props}>{props.children}</CookieContext.Provider>;
};

export default CookieProvider;

export function useCookieAcceptState() {
  return React.useContext(CookieContext);
}
