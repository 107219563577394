import { useEffect, useState } from 'react';
import { lock, unlock } from 'tua-body-scroll-lock';

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

/**
 * Locks the body's scroll
 * @param initialLocked - Defines the initial locked state. When changed, {@link setLocked} is used
 * to set the new locked state.
 * @param htmlElement - An element that should still be scrollable
 */
function useLockedBody(
  initialLocked = false,
  htmlElement?: HTMLElement | string,
): [locked: boolean, setLocked: (locked: boolean) => void] {
  const [locked, setLocked] = useState(initialLocked);

  useIsomorphicLayoutEffect(() => {
    if (!locked) {
      return;
    }

    if (typeof htmlElement === 'string') {
      const ele = document.getElementById(htmlElement);

      lock(ele);

      return () => {
        unlock(ele);
      };
    }

    lock(htmlElement);

    return () => {
      unlock(htmlElement);
    };
  }, [locked, htmlElement]);

  useEffect(() => {
    if (locked !== initialLocked) {
      setLocked(initialLocked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLocked]);

  return [locked, setLocked];
}

export default useLockedBody;
