/**
 * This utils is just a typed version of gtag from google tag manager.
 * Filled with overloads for every type of event you can send to gtag.
 */
import * as Gtag from './types';
export * as Gtag from './types';

/** Configure site to use the correct id */
function gtag(command: 'config', tagId: string, additional?: Gtag.ConfigAdditionalConfig): void;
function gtag(command: 'js', config: Date): void;

/**
 * When an item is removed from the cart
 */
function gtag(
  command: 'event',
  config: 'add_shipping_info',
  additional: Gtag.AddShippingInfoAdditionalConfig,
): void;

/**
 * When an item is removed from the cart
 */
function gtag(
  command: 'event',
  config: 'add_payment_info',
  additional: Gtag.AddPaymentInfoAdditionalConfig,
): void;

/**
 * When an item is removed from the cart
 */
function gtag(
  command: 'event',
  config: 'remove_from_cart',
  additional: Gtag.RemoveFromCartAdditionalConfig,
): void;

/**
 * When an item is removed from the cart
 */
function gtag(
  command: 'event',
  config: 'purchase',
  additional: Gtag.PurchaseAdditionalConfig,
): void;

/**
 * When a user select an item in a item list
 */
function gtag(
  command: 'event',
  config: 'select_item',
  additional: Gtag.SelectItemAdditionalConfig,
): void;

/**
 * When a user views an item
 */
function gtag(
  command: 'event',
  config: 'view_item',
  additional: Gtag.ViewItemAdditionalConfig,
): void;

/**
 * View an item list
 */
function gtag(
  command: 'event',
  config: 'page_view',
  additional: Gtag.PageViewAdditionalConfig,
): void;

/**
 * View an item list
 */
function gtag(
  command: 'event',
  config: 'view_item_list',
  additional: Gtag.ViewItemListAdditionalConfig,
): void;

/**
 * When an item is added to cart
 */
function gtag(
  command: 'event',
  config: 'add_to_cart',
  additional: Gtag.AddToCartAdditionalConfig,
): void;

function gtag(): void {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer?.push(arguments);
}

export default gtag;
