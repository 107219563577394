import React, { useEffect } from 'react';

const listenerCallbacks = new WeakMap();

require('intersection-observer');

const DEFAULT_ROOT = null;
const DEFAULT_ROOT_MARGIN = '0px';
const DEFAULT_THRESHOLD = [0];

// global observer, as to reuse the instance between hook implementations
let observer: IntersectionObserver | undefined;

function handleIntersections(entries: IntersectionObserverEntry[]) {
  entries.forEach(entry => {
    if (listenerCallbacks.has(entry.target)) {
      const cb = listenerCallbacks.get(entry.target);

      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        observer?.unobserve(entry.target);
        listenerCallbacks.delete(entry.target);
        cb();
      }
    }
  });
}

function getIntersectionObserver({
  root = DEFAULT_ROOT,
  rootMargin = DEFAULT_ROOT_MARGIN,
  threshold = DEFAULT_THRESHOLD,
  ...settings
}: IntersectionObserverInit = {}) {
  if (observer === undefined) {
    observer = new IntersectionObserver(handleIntersections, {
      root,
      rootMargin,
      threshold,
      ...settings,
    });
  }
  return observer;
}

function useIntersection(
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
  settings?: IntersectionObserverInit,
) {
  useEffect(() => {
    const target = ref.current;
    if (target) {
      const observer = getIntersectionObserver(settings);
      listenerCallbacks.set(target, callback);
      observer.observe(target);

      return () => {
        listenerCallbacks.delete(target);
        observer.unobserve(target);
      };
    }
  }, [callback, ref, settings]);
}

export default useIntersection;
