import React from 'react';

import OrArray from 'types/OrArray';
import { cx } from 'utils';
import styles from './Accordion.module.css';

export interface AccordionProps {
  children: OrArray<React.ReactNode>;
  shown?: boolean;
}

/**
 * Accordion - A component to toggle between shown and hidden
 * via height and opacity.
 *
 * Warning: Do not use this if you want to unmount the children
 * when closed. If you need to unmount children, use Expandable
 */
const Accordion: React.FC<AccordionProps> = ({ children, shown }) => {
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const handler = () => {
      if (!ref.current) return;
      setHeight(ref.current.getBoundingClientRect().height);
    };
    handler();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [ref, children]);

  return (
    <div
      aria-expanded={shown}
      className={cx(styles.root, shown && styles.shown)}
      style={{
        height: shown ? `${height}px` : 0,
      }}
    >
      <div className={styles.content} ref={ref}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;
