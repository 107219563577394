import React from 'react';

import Modal from 'components/container/Modal';
import CheckoutContent from 'components/partials/CheckoutContent';
import Backdrop from 'components/ui/Backdrop';
import DrawerHeader from 'components/ui/DrawerHeader';
import IconButton from 'components/ui/IconButton';
import { useCart } from 'contexts/CartContext';
import Close from 'icons/close.svg';
import { cx } from 'utils';
import classes from './Checkout.module.css';

const Checkout: React.FC = () => {
  const [shown, setShown] = useCart().cartDrawerShown;
  const onClose = () => setShown(false);

  return (
    <Modal open={shown}>
      {({ id, open }) => (
        <>
          <div className={cx(classes.menu, open && classes.shown)}>
            <div className={classes.inner}>
              <DrawerHeader className={classes.header}>
                <IconButton onClick={onClose} square={13}>
                  <Close />
                </IconButton>
              </DrawerHeader>
              {/* Due to how the ingrid widget works this component needs to be mounted at all times. */}
              <CheckoutContent id={id} />
            </div>
          </div>
          <Backdrop onClick={onClose} open={shown} className={classes.backDrop} />
        </>
      )}
    </Modal>
  );
};

export default Checkout;
