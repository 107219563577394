import { StaticContent } from 'types/StaticContent';
import { sanityClient } from '../client';

export default async function getStaticContent(): Promise<Partial<StaticContent>> {
  try {
    const [conditionDescription, bookTimeDescription] = await Promise.all([
      await sanityClient.fetch<{ text: string }>(
        `*[_type == "static-content" && _id == "condition-description"] { text }[0]`,
      ),
      await sanityClient.fetch<{ text: string }>(
        `*[_type == "static-content" && _id == "book-time-description"] { text }[0]`,
      ),
    ]);

    const returnVal: Partial<StaticContent> = {
      bookTimeDescription: bookTimeDescription.text,
      conditionDescription: conditionDescription.text,
    };

    (Object.keys(returnVal) as (keyof StaticContent)[]).forEach(key =>
      returnVal[key] === undefined ? delete returnVal[key] : {},
    );

    return returnVal;
  } catch {
    return {};
  }
}
