import React from 'react';

import api from 'api';
import ShoppingCartItem from 'components/ui/ShoppingCartItem';
import Typography from 'components/ui/Typography';
import { CartContext } from 'contexts/CartContext';
import { CartData } from 'queries';
import { CartProduct } from 'types/Product';
import { SanityUrlBuilder } from 'utils';
import { parseItemError, parseItemWarning, ResultCode } from 'utils/errors';
import formatPrice from 'utils/formatPrice';
import classes from './Items.module.css';

type ItemsProps = {
  cartProducts?: CartData | undefined;
  onRemove: (cartProduct: CartProduct) => void;
  products: CartContext['products'];
};

const Items: React.FC<ItemsProps> = props => {
  const builder = new SanityUrlBuilder(api().sanity.client);
  return (
    <div className={classes.items}>
      {props.products.map(({ item, product }) => {
        const img = (() => {
          const media = product.media[0];

          if (media.type == 'image') {
            return media.source;
          }
          return media.thumbnail;
        })();

        const cartItem = props.cartProducts?.[item.reference];

        let label: ResultCode | undefined = undefined;
        if (cartItem?.warning != null) {
          label = parseItemWarning(cartItem.warning).codes[0];
        }
        if (cartItem?.error != null) {
          label = parseItemError(cartItem.error).codes[0];
        }

        return (
          <ShoppingCartItem
            key={item.reference}
            brand={product.brand}
            image={builder.image(img).height(140).width(100).url()}
            label={label}
            onRemove={() => props.onRemove({ item, product })}
            name={product.name}
            price={item.price}
          />
        );
      })}
      <div className={classes.sum}>
        <Typography component="p" variant="b">
          Summa (exkl. frakt)
        </Typography>
        <Typography component="p" variant="b">
          {formatPrice(props.products.reduce((t, { product }) => t + product.price, 0))}:-
        </Typography>
      </div>
    </div>
  );
};

export default Items;
