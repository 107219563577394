import { SanityFileSource, SanityImageSource } from '@sanity/asset-utils';

import { Media } from 'types/Media';
import { ProductCondition, ProductSmall } from 'types/Product';

export function processProductSmall(product: Record<string, unknown>): ProductSmall {
  return {
    brand: product.brand as string,
    condition: product.condition as ProductCondition,
    groupReference: product.groupReference as string,
    media: (product.media as Record<string, unknown>[])
      .map((media): null | Media => {
        if (media._type === 'image') {
          return {
            source: media as SanityImageSource,
            type: 'image',
          };
        } else if (media._type === 'video-media') {
          return {
            thumbnail: media.thumbnail as SanityImageSource,
            type: 'video',
            video: media.video as SanityFileSource,
          };
        }

        return null;
      })
      .filter((v): v is NonNullable<typeof v> => v !== null)[0],
    name: product.name as string,
    price: parseFloat(product.price as string),
    slug: (product.slug as { current: string }).current,
  };
}
