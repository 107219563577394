import React, { useContext, useState } from 'react';

import Checkout from 'components/modals/Checkout';
import SideMenu from 'components/modals/SideMenu';
import Footer from 'components/sections/Footer';
import { FooterMenuItem, NavbarMenuItem } from 'types/Menu';
import Navbar from '../Navbar';
import classes from './Layout.module.css';

export type LayoutProps = {
  children: React.ReactNode;
  footerMenu: FooterMenuItem[];
  navbarHeaderColor?: 'white' | 'secondary';
  navbarMenu: NavbarMenuItem[];
  nextRelease?: Date;
  showWelcomeBanner?: boolean;
};

const Layout: React.FC<LayoutProps> = props => {
  const [showNavbarAndFooter, setShowNavbarAndFooter] = useState(true);

  return (
    <LayoutComponentContext.Provider value={{ setShowNavbarAndFooter }}>
      {showNavbarAndFooter && (
        <Navbar
          showWelcomeBanner={props.showWelcomeBanner}
          nextRelease={props.nextRelease}
          color={props.navbarHeaderColor ?? 'white'}
          items={props.navbarMenu.filter(item => item.position === 'above-line')}
        />
      )}
      <Checkout />
      <SideMenu items={props.navbarMenu} />
      <div className={classes.layoutContent}>
        <main>{props.children}</main>
        {showNavbarAndFooter && <Footer links={props.footerMenu} />}
      </div>
    </LayoutComponentContext.Provider>
  );
};

const LayoutComponentContext = React.createContext({
  setShowNavbarAndFooter: (v: boolean): void => void v,
});

export function useLayoutComponent() {
  // This hook is only here because phone version of product page shouldn't have a footer and navbar
  // If you need to more props and do more stuff in the future, i would advise to refactor this component instead
  // This is not a solution that a lot should depend on
  const ctx = useContext(LayoutComponentContext);

  return ctx;
}

export default Layout;
