/**
 * Change text depending of the count. Remove this if possible
 * @example
 * const getText = pluralize("{count} item", "{count} items");
 *
 * getText(1) // 1 item
 * getText(31) // 31 items
 *
 * @param single - The single version of the string
 * @param plural - The plural version of the string
 * @returns a function that can generate a text from a count
 */
function pluralize(single: string, plural: string) {
  return (count: number) => {
    if (count === 1) {
      return single.replace(/{count}/g, `${count}`);
    }
    return plural.replace(/{count}/g, `${count}`);
  };
}

export default pluralize;
