import { sanityClient } from '../client';
import type { GetProductFeedOptions } from '../getProductFeed';
import { SELECT_ALL_COLLECTION_ELEMENTS } from './getCollectionElements';

export default async function getCollectionElementCount(
  options: GetProductFeedOptions,
): Promise<number> {
  try {
    return await sanityClient.fetch<number>(`count(${SELECT_ALL_COLLECTION_ELEMENTS})`, options);
  } catch {
    return 0;
  }
}
