import { sanityClient } from '../client';

export default async function getAllPages() {
  try {
    const data = await sanityClient.fetch<{ slug: string; title: string }[]>(
      `*[_type == "page"] { "slug": slug.current, title }`,
    );
    return data;
  } catch {
    return [];
  }
}
