import React from 'react';

import Clickable from 'components/ui/Clickable';
import { cx } from 'utils';
import styles from './IconButton.module.css';

export interface IconButtonProps extends React.ComponentProps<typeof Clickable> {
  icon?: React.ReactNode;
  square?: number;
}

const IconButton: React.FC<IconButtonProps> = props => {
  const { children, className, icon, square, style, ...restProps } = props;
  return (
    <Clickable
      className={cx(styles.button, className)}
      style={{
        backgroundImage: typeof icon === 'string' ? `url('/icons/${icon}.svg')` : '',
        ...(square
          ? {
              height: `${square + 16}px`,
              width: `${square + 16}px`,
            }
          : {}),
        ...style,
      }}
      {...restProps}
    >
      {typeof icon !== 'string' && icon}
      {children}
    </Clickable>
  );
};

export const DummyIconButton = () => (
  <IconButton aria-disabled className={styles.dummyButton} icon="close" />
);

export default IconButton;
