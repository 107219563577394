import React, { useCallback, useContext } from 'react';
import { useRouter } from 'next/router';

type SideMenuContext = {
  setShown: (v: boolean) => void;
  shown: boolean;
};

const SideMenuContext = React.createContext<SideMenuContext>({
  setShown: () => void 0,
  shown: false,
});

type SideMenuProps = {
  children: React.ReactNode;
};

const SideMenuProvider: React.FC<SideMenuProps> = props => {
  const router = useRouter();

  const [shown, setShownRaw] = React.useState(/#meny/.test(router.asPath));

  const setShown = useCallback((v: boolean) => {
    setShownRaw(v);
  }, []);

  return (
    <SideMenuContext.Provider value={{ setShown, shown }}>
      {props.children}
    </SideMenuContext.Provider>
  );
};

export function useSideMenuShown(): [boolean, (v: boolean) => void] {
  const ctx = useContext(SideMenuContext);

  return [ctx.shown, ctx.setShown];
}

export default SideMenuProvider;
