function generateId(len = 16) {
  const pool = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let id = '';

  while (id.length < len) {
    id += pool[~~(Math.random() * pool.length)];
  }

  return id;
}

export default generateId;
