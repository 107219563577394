import React, { HTMLAttributes } from 'react';

// An unstyled component that automatically switches between `<a>` and
// `<button>` based on the presence of a `href` and automatically applies some
// nice attribute defaults.`.

export interface ClickableProps extends HTMLAttributes<HTMLAnchorElement & HTMLButtonElement> {
  disabled?: boolean;
  href?: string;
  target?: React.ComponentProps<'a'>['target'];
}

const Clickable = React.forwardRef<HTMLAnchorElement & HTMLButtonElement, ClickableProps>(
  ({ disabled, href, ...restProps }, ref) => {
    return href != null ? (
      disabled ? (
        // Support `disabled` on links by removing the `href`. Also override
        // `onClick` to match `<button disabled>`. There might be more events to
        <a
          ref={ref}
          {...restProps}
          aria-disabled={disabled}
          onClick={() => {
            /*nothing*/
          }}
        />
      ) : (
        <a ref={ref} href={href} {...restProps} />
      )
    ) : (
      // `type` can be overridden via `restProps`.
      <button ref={ref} type="button" aria-disabled={disabled} disabled={disabled} {...restProps} />
    );
  },
);

Clickable.displayName = 'Clickable';

export default Clickable;
