/* eslint-disable @typescript-eslint/no-explicit-any */
import { PRODUCTS_ARCHIVED_FEED } from 'consts';
import dayjs from 'dayjs';

import { Look } from 'types/Look';
import { ScheduledRelease } from 'types/ScheduledRelease';
import { sanityClient } from '../client';
import {
  PUBLISHED_PRODUCTS_CONDITION,
  PUBLISHED_PROMOTED_SCHEDULED_RELEASES,
  RELEASED_CONDITION,
} from '../utils';
import elementToProductSmall from './helper/elementsToProducts';

export default async function getPreviousScheduledRelease(
  offset = 0,
  fromDate?: Date,
): Promise<ScheduledRelease | null> {
  try {
    // A lot of any here, but it's we don't know the types either
    const data = await sanityClient.fetch<{
      elements: unknown[];
      releaseAt: string;
    }>(
      `*[
        _type == "scheduledRelease" &&
        ${RELEASED_CONDITION} &&
        ${PUBLISHED_PROMOTED_SCHEDULED_RELEASES}
      ] | order(dateTime(releaseAt) desc) [$offset]{
    releaseAt,
    previewCount,
    elements[${PUBLISHED_PRODUCTS_CONDITION}]->{
      ...,
      brand->,
      products[]{..., product->{..., brand->,"videos": media[]->{...}}},
    }
  }`,
      {
        archivedAfter: PRODUCTS_ARCHIVED_FEED(),
        fromDate: fromDate?.toISOString() ?? new Date().toISOString(),
        offset,
      },
    );

    if (dayjs(new Date(data.releaseAt)).diff(new Date(), 'day', true) >= 14) {
      return null;
    }

    const final = data.elements
      .map((v: any) => {
        if (v._type === 'product') {
          return elementToProductSmall(v);
        }
        const look: Look = {
          image: v.image,
          name: v.name,
          products: v.products
            .map((vv: any) => {
              const element = elementToProductSmall(vv.product);

              if (!element) {
                return undefined;
              }

              return {
                product: element,
                x: vv.x / 100,
                y: vv.y / 100,
              };
            })
            .filter((v: any) => typeof v !== 'undefined'),
        };

        return look;
      })
      .filter((v): v is NonNullable<typeof v> => v !== null);

    return { items: final, releaseAt: data.releaseAt };
  } catch {
    return null;
  }
}
