import { SanityFileSource, SanityImageSource } from '@sanity/asset-utils';

import { Media } from 'types/Media';
import { Product, ProductCondition } from 'types/Product';
import { sanityClient } from '../client';

export default async function getProduct(slug: string): Promise<Product | null> {
  try {
    const data = await sanityClient.fetch<Record<string, unknown> | null>(
      `*[slug.current == $slug][0] {
        ...,
        "brand": brand->name,
        "isReleased": dateTime((*[_type == "scheduledRelease" && ^._id in elements[]._ref] | order(dateTime(releaseAt) asc))[0].releaseAt) <= dateTime(now())
      }`,
      { slug },
    );

    if (data == null) {
      return null;
    }

    return {
      brand: data.brand as string,
      condition: data.condition as ProductCondition,
      description: data.description as string,
      groupReference: data.groupReference as string,
      isReleased: data.isReleased as boolean,
      material: (data.material ?? null) as string | null,
      media: (data.media as Record<string, unknown>[])
        .map((v): null | Media => {
          if (v._type === 'image') {
            return {
              source: v as SanityImageSource,
              type: 'image',
            };
          } else if (v._type === 'video-media') {
            return {
              thumbnail: v.thumbnail as SanityImageSource,
              type: 'video',
              video: v.video as SanityFileSource,
            };
          }

          return null;
        })
        .filter((v): v is NonNullable<typeof v> => v !== null),
      name: data.name as string,
      price: parseFloat(data.price as string),
      sizeAndFit: data.sizeAndFit as string,
      slug,
      washAdvice: (data.washAdvice ?? null) as string | null,
    };
  } catch {
    return null;
  }
}
