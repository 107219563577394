import React, { useEffect, useState } from 'react';

import { cx } from 'utils';
import InputWrapper, { InputWrapperParentProps } from '../InputWrapper';
import classes from './Input.module.css';

export type InputProps = Omit<InputWrapperParentProps & React.ComponentProps<'input'>, 'ref'>;

function Input(props: InputProps) {
  const { block, error, errorMessage, label, required, ...rest } = props;
  const [hasTextContent, setHasTextContent] = useState(false);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (props.value) {
      setHasTextContent(true);
    } else {
      setHasTextContent(false);
    }
  }, [props.value]);

  return (
    <InputWrapper
      active={hasTextContent || focused}
      label={label}
      error={error}
      for={props.id}
      errorMessage={errorMessage}
      required={required}
      block={block}
    >
      <input
        {...rest}
        onChange={e => {
          // Update input wrapper active state
          if (e.currentTarget.value === '') {
            setHasTextContent(false);
          } else {
            setHasTextContent(true);
          }

          rest.onChange?.(e);
        }}
        onFocus={e => {
          setFocused(true);
          rest.onFocus?.(e);
        }}
        onBlur={e => {
          setFocused(false);
          rest.onBlur?.(e);
        }}
        required={required}
        className={cx(classes.input, rest.className)}
      />
    </InputWrapper>
  );
}

export default Input;
