import { PRODUCTS_ARCHIVED_PAGE, PROMOTED_ARCHIVED } from 'consts';

import { ProductSmall } from 'types/Product';
import { processProductSmall } from 'utils/processProductSmall';
import { sanityClient } from '../client';
import { RELEASED_CONDITION } from '../utils';

export default async function getBrandsProducts(brand: string): Promise<ProductSmall[]> {
  try {
    const data = await sanityClient.fetch<Record<string, unknown>[]>(
      `*[
        _type == "product" &&
        count(*[
          _type == "scheduledRelease" &&
          ^._id in elements[]._ref &&
          ${RELEASED_CONDITION} && (
            (             dateTime($archivedAfter)         < dateTime(releaseAt)) ||
            (^.promote && dateTime($promotedArchivedAfter) < dateTime(releaseAt))
          )
        ]) >= 1 &&
        brand->slug.current == $brand
      ] {
        ...,
        "brand": brand->name,
        "videos": media[]->{..., file->{url} }
      }`,
      {
        archivedAfter: PRODUCTS_ARCHIVED_PAGE(),
        brand,
        promotedArchivedAfter: PROMOTED_ARCHIVED(),
      },
    );

    return data.map(processProductSmall);
  } catch {
    return [];
  }
}
