import { SanityFileSource, SanityImageSource } from '@sanity/asset-utils';

import { Home } from 'types/Home';
import { Media } from 'types/Media';
import { sanityClient } from '../client';

function mapMedia(media: Record<string, unknown>): Media {
  if (media._type === 'image') {
    return {
      source: media as SanityImageSource,
      type: 'image',
    };
  } else if (media._type === 'video-media') {
    return {
      thumbnail: media.thumbnail as SanityImageSource,
      type: 'video',
      video: media.video as SanityFileSource,
    };
  }

  throw new Error('Invalid media type for block');
}

export default async function getHome(): Promise<Home> {
  const home = await sanityClient.fetch<Home>(`*[_type == "home" && _id == "home"][0] { ... }`);

  home.blocks = home.blocks.map(block => {
    switch (block._type) {
      case 'hero': {
        block.media = block.media.map(mapMedia) as [Media];
        break;
      }
      case 'split':
        block.left[0].media = block.left[0].media.map(mapMedia) as [Media];
        block.right[0].media = block.right[0].media.map(mapMedia) as [Media];
        break;
    }
    return block;
  });

  return home;
}
