import { Look } from 'types/Look';
import { ProductSmall } from 'types/Product';

export default async function getProductFeed(options: {
  collection?: string | null;
  limit?: number;
  offset?: number;
}): Promise<(ProductSmall | Look)[]> {
  const params = new URLSearchParams();
  params.set('limit', (options.limit ?? 1).toString());
  params.set('offset', (options.offset ?? 0).toString());

  if (options.collection != null) params.set('collection', options.collection);

  const res = await fetch(`/frontend-api/feed?${params}`);
  const body = await res.json();

  return body;
}
