/* eslint-disable @typescript-eslint/no-explicit-any */

import groq from 'groq';

import { Look } from 'types/Look';
import { ProductSmall } from 'types/Product';
import { sanityClient } from '../client';
import type { GetProductFeedOptions } from '../getProductFeed';
import elementToProductSmall from '../scheduled-releases/helper/elementsToProducts';
import { RELEASED_CONDITION } from '../utils';

const SELECT_SCHEDULED_RELEASE_CONTAINING_PARENT_ID = groq`
  *[
    _type == "scheduledRelease" &&
    ^._id in elements[]._ref &&
    ${RELEASED_CONDITION} && (
      (             dateTime($archivedAfter)         < dateTime(releaseAt)) ||
      (^.promote && dateTime($promotedArchivedAfter) < dateTime(releaseAt))
    )
  ]
`;

export const SELECT_ALL_COLLECTION_ELEMENTS = groq`
  *[
    _type == "collection" &&    // Select the collection where
    slug.current == $collection // the current slug matches the collection parameter
  ]["content"][] {
    "elements": select(                                           // Here comes the spaghetti-monster
      (
        _type == "scheduledRelease" &&                            // If the current collection entry references a released and non-archived scheduled release
        dateTime(now()) > dateTime(@->releaseAt) &&               // and the release date has passed
        dateTime($promotedArchivedAfter) < dateTime(@->releaseAt) // and the promoted archive date has not passed
      )
        => @->elements[(
          (               dateTime($archivedAfter)         < dateTime(^->releaseAt)) || // Only return products which have not passed the archive date
          (@->promote  && dateTime($promotedArchivedAfter) < dateTime(^->releaseAt))    // Also return promoted products which have not passed the promoted archive date
        )]->{ ..., "releaseAt": ^->releaseAt }
           | order(dateTime(releaseAt) desc),                     // Then we return the elements of that release
      (
        _type == "category"                                       // If the current collection entry references a category
      ) => *[
        _type == "product" &&
        references(^._ref) &&
        count(${SELECT_SCHEDULED_RELEASE_CONTAINING_PARENT_ID}) >= 1
      ] {
        ...,
        "releaseAt": (
          ${SELECT_SCHEDULED_RELEASE_CONTAINING_PARENT_ID}
        )[0].releaseAt
      } // Then we return the products which are part of a released and non-archived scheduled release
    )
  }["elements"][] | order(dateTime(releaseAt) desc)
`;

const QUERY = groq`
  (${SELECT_ALL_COLLECTION_ELEMENTS})[($offset * $pageLength)..(($offset * $pageLength) +($limit * $pageLength)) - 1] {
    ...,
    brand->,
    "videos": media[]->{ ..., file->{url} },
    products[]{..., product->{..., brand->,"videos": media[]->{...}}}
  }
`;

export default async function getCollectionElements(
  options: GetProductFeedOptions,
): Promise<(ProductSmall | Look)[]> {
  try {
    const data = await sanityClient.fetch<Record<string, unknown>[]>(QUERY, options);

    return data
      .filter((element: any) => element != null)
      .map((element: any) => {
        if (element._type === 'product') {
          return elementToProductSmall(element);
        }

        const look: Look = {
          image: element.image,
          name: element.name,
          products: element.products
            .map((point: any) => {
              const product = elementToProductSmall(point.product);

              if (product == null) {
                return undefined;
              }

              return {
                product,
                x: point.x / 100,
                y: point.y / 100,
              };
            })
            .filter(
              (point: unknown): point is NonNullable<typeof point> => typeof point !== 'undefined',
            ),
        };

        return look;
      })
      .filter((element): element is NonNullable<typeof element> => element != null);
  } catch {
    return [];
  }
}
