import React from 'react';
import FocusTrap from 'focus-trap-react';
import Link from 'next/link';

import Modal from 'components/container/Modal';
import Backdrop from 'components/ui/Backdrop';
import DrawerHeader from 'components/ui/DrawerHeader';
import IconButton from 'components/ui/IconButton';
import Typography from 'components/ui/Typography';
import { useSideMenuShown } from 'contexts/SideMenu';
import Close from 'icons/close.svg';
import Instagram from 'icons/instagram.svg';
import Tiktok from 'icons/tiktok.svg';
import { NavbarMenuItem } from 'types/Menu';
import { cx } from 'utils';
import classes from './SideMenu.module.css';

export type SideMenuProps = {
  items: NavbarMenuItem[];
};

const SideMenu: React.FC<SideMenuProps> = props => {
  const [shown, setShown] = useSideMenuShown();
  const { items } = props;

  return (
    <Modal open={shown}>
      {({ id, open }) => {
        return (
          <FocusTrap active={open}>
            <div {...{ inert: open ? undefined : '' }}>
              <div className={cx(classes.menu, open && classes.shown)}>
                <div id={id} className={classes.inner}>
                  <DrawerHeader className={classes.header}>
                    <IconButton onClick={() => setShown(false)} square={13}>
                      <Close />
                    </IconButton>
                  </DrawerHeader>
                  <div className={classes.content}>
                    {items
                      .filter(v => v.position === 'above-line')
                      .map(item => (
                        <Item
                          variant="bold"
                          onClick={() => setShown(false)}
                          item={item}
                          key={item.text}
                        />
                      ))}
                    <div className={classes.divider}></div>
                    {items
                      .filter(v => v.position === 'under-line')
                      .map(item => (
                        <Item
                          variant="thin"
                          onClick={() => setShown(false)}
                          item={item}
                          key={item.text}
                        />
                      ))}
                  </div>
                  <div className={classes.innerFooter}>
                    <IconButton
                      aria-label="go to arkivet tiktok"
                      href="https://www.tiktok.com/@arkivetsthlm"
                      target="_blank"
                      onClick={() => setShown(false)}
                      square={24}
                    >
                      <Tiktok />
                    </IconButton>
                    <IconButton
                      aria-label="go to arkivet instagram"
                      href="https://www.instagram.com/arkivetsthlm"
                      target="_blank"
                      onClick={() => setShown(false)}
                      square={24}
                    >
                      <Instagram />
                    </IconButton>
                  </div>
                </div>
              </div>
              <Backdrop onClick={() => setShown(false)} open={open} className={classes.backDrop} />
            </div>
          </FocusTrap>
        );
      }}
    </Modal>
  );
};

const Item: React.FC<
  { item: NavbarMenuItem; variant: 'bold' | 'thin' } & React.ComponentProps<'a'>
> = props => {
  const { item, variant, ...restProps } = props;

  return (
    <div className={classes.item}>
      <Link href={item.href} passHref>
        <a className={classes.button} {...restProps}>
          <Typography
            className={classes.itemText}
            variant={variant === 'bold' ? 'h2' : 'h2-light'}
            component="p"
          >
            {item.text}
          </Typography>
        </a>
      </Link>
    </div>
  );
};

export default SideMenu;
