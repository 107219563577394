/**
 * Formats a price string or number with digit grouping and two decimal places.
 *
 * @remarks
 * If the decimal point is 00 then that part will be removed
 *
 * @param price - A string or number representing the price to format.
 * @returns A string representing the formatted price.
 */
export default function formatPrice(price: string | number): string {
  if (typeof price === 'number') {
    return formatPrice(price.toString());
  }

  const [digits, decimals] = price.split(/[,.]+/);

  const recursiveDigitPrettier = (value: string): string => {
    if (/^(\d{0,3})$/.test(value)) {
      return value;
    }

    return value.replace(/^(\d+)(\d{3})$/, (_, p1, p2) => {
      return `${recursiveDigitPrettier(p1)} ${p2}`;
    });
  };

  const prettierDigits = recursiveDigitPrettier(digits);

  if (/^0+$/.test(decimals) || !decimals) return prettierDigits;
  return `${prettierDigits},${
    decimals.slice(0, 2) + Array(Math.max(0, 2 - decimals.length)).fill('0')
  }`;
}
