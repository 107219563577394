function chunk<T>(arr: T[], sizeOfChunks: number): T[][] {
  if (sizeOfChunks <= 0) {
    throw new Error('Chunk size must be greater or equal than 0');
  }

  const result: T[][] = [];

  for (let i = 0; i < arr.length; i += sizeOfChunks) {
    result.push(arr.slice(i, i + sizeOfChunks));
  }

  return result;
}

export default chunk;
