import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import type { Extensions } from 'bananas-commerce';

import { getBananasCommerce } from 'utils';

export type CartOptions = { references: string[] };
export type CartData = Record<string, Extensions.Cart.ResultItem>;

async function fetchData(keyOpts: CartOptions): Promise<CartData> {
  const bcom = getBananasCommerce();

  if (keyOpts.references.length === 0) {
    return {};
  }

  const newAvailabilityMap = Object.fromEntries(
    await bcom.pos
      .cart({
        items: keyOpts.references.map(reference => ({
          quantity: 1,
          reference,
        })),
      })
      .then(v => {
        if (v._type !== 'success') {
          throw v;
        } else {
          return v.items.map(v => [v.reference, { ...v }]);
        }
      }),
  );
  return newAvailabilityMap as CartData;
}

/**
 * Calls cart verify to check if the cart is valid or not
 */
function useCartVerification(
  keyOpts: CartOptions,
  opts?: Pick<
    UseQueryOptions<CartData, unknown, CartData, ['cart', CartOptions]>,
    'refetchOnWindowFocus' | 'staleTime' | 'enabled'
  >,
) {
  return useQuery(
    useCartVerification.queryKey(keyOpts),
    async (): Promise<CartData> => await useCartVerification.fetchData(keyOpts),
    {
      cacheTime: 0,
      staleTime: Infinity,
      ...opts,
    },
  );
}

useCartVerification.queryKey = (keyOpts: CartOptions): ['cart', CartOptions] => [
  'cart',
  { references: keyOpts.references.sort() },
];
useCartVerification.fetchData = fetchData;

export default useCartVerification;
