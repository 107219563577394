import { useEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const useHeight = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!ref.current) {
      throw new Error('ref is not set');
    }

    setHeight(ref.current.getBoundingClientRect().height);
  }, [ref]);

  useResizeObserver(ref, entry => setHeight(entry.contentRect.height));

  return [ref, height] as const;
};

export default useHeight;
