import React, { useContext } from 'react';
import { v4 as uuid } from 'uuid';

import useLocalStorage from 'hooks/useLocalStorage';

type User = { id: string };

const UserContext = React.createContext<User>({ id: '' });

export const useUser = () => useContext(UserContext);

export default function UserProvider(props: { children: React.ReactNode }) {
  const [id] = useLocalStorage('id', uuid());

  return <UserContext.Provider value={{ id }}>{props.children}</UserContext.Provider>;
}
