import { CountryCode, Extensions, generateClient } from 'bananas-commerce';
import Arkivet from 'bananas-commerce-arkivet-extension';
import getConfig from 'next/config';

/** Returns the default instance of {@link BananasCommerce}. */
export default function getBananasCommerce() {
  const { publicRuntimeConfig } = getConfig();

  const bananasCommerce = generateClient({
    baseUrl: publicRuntimeConfig.bcom.baseUrl,
    config: {
      countryCode: publicRuntimeConfig.bcom.countryCode as CountryCode,
      siteCode: publicRuntimeConfig.bcom.siteCode,
    },
    extensions: [
      new Extensions.Adyen(),
      new Arkivet(),
      new Extensions.Pos(),
      new Extensions.Ingrid(),
    ],
    init: {
      credentials: 'include',
    },
  });

  return bananasCommerce;
}
