// Hook
import React, { RefObject } from 'react';

export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
  stopPropagation?: boolean,
) => {
  const handleClick = (e: MouseEvent) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (e.target instanceof HTMLElement && ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useClickOutside;
