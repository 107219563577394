/** A sanity condition which ensures the `releaseAt` date has been passed */
export const RELEASED_CONDITION = `dateTime(now()) > dateTime(releaseAt)`;

/**
 * A sanity condition which ensures the `archivedAfter` option has not been passed.
 *
 * @example Query for all still published scheduled releases:
 * ```groq
 * *[
 *   _type == "scheduledRelease" &&
 *   ${PUBLISHED_SCHEDULED_RELEASES}
 * ] {
 *   elements[] -> { ... }
 * }
 * ```
 */
export const PUBLISHED_SCHEDULED_RELEASES = `dateTime($archivedAfter) < dateTime(releaseAt)`;
/**
 * A sanity condition which ensures the `promotedArchivedAfter` option has not
 * been passed. Will usually be used in combination with {@link PUBLISHED_PRODUCTS_CONDITION}
 * to hide non-promoted products after a certain date and promoted products after another.
 *
 * @example Query for all published scheduled releases with promoted products:
 * ```groq
 * *[
 *   _type == "scheduledRelease" &&
 *   ${PUBLISHED_SCHEDULED_RELEASES} &&
 *   ${PUBLISHED_PROMOTED_SCHEDULED_RELEASES}
 * ] {
 *   elements[${PUBLISHED_PRODUCTS_CONDITION}] -> { ... }
 * }
 * ```
 */
export const PUBLISHED_PROMOTED_SCHEDULED_RELEASES = `dateTime($promotedArchivedAfter) < dateTime(releaseAt)`;

/**
 * A sanity condition which filters products that are not archived. Needs to be
 * applied to the `elements` array in a query.
 *
 * @example Query for all published scheduled releases with promoted products:
 * ```groq
 * *[
 *   _type == "scheduledRelease" &&
 *   ${PUBLISHED_SCHEDULED_RELEASES} &&
 *   ${PUBLISHED_PROMOTED_SCHEDULED_RELEASES}
 * ] {
 *   elements[${PUBLISHED_PRODUCTS_CONDITION}] -> { ... }
 * }
 * ```
 */
export const PUBLISHED_PRODUCTS_CONDITION = `(
  (               dateTime($archivedAfter)         < dateTime(^.releaseAt)) ||
  (@->promote  && dateTime($promotedArchivedAfter) < dateTime(^.releaseAt))
)`;
