import React from 'react';

import { cx } from 'utils';
import styles from './Label.module.css';

export interface LabelProps extends React.ComponentProps<'div'> {
  children: React.ReactNode;
  color?: 'yellow' | 'red';
}

const Label: React.FC<LabelProps> = props => {
  const { children, className, ...restProps } = props;
  return (
    <div
      className={cx(
        styles.label,
        {
          [styles.yellow]: props.color === 'yellow' || !props.color,
          [styles.red]: props.color === 'red',
        },
        className,
      )}
      {...restProps}
    >
      {children}
    </div>
  );
};

export default Label;
