import { PRODUCTS_ARCHIVED_FEED, PROMOTED_ARCHIVED } from 'consts';

import { Look } from 'types/Look';
import { ProductSmall } from 'types/Product';
import getCollectionElementPageCount from './products/getCollectionElementPageCount';
import getCollectionElements from './products/getCollectionElements';
import getPreviousScheduledReleases from './scheduled-releases/getPreviousScheduledReleases';

export type GetProductFeedOptions = {
  /** After which date do we filter out "archived" products. Defaults to 14 days for the feed and a month for collections */
  archivedAfter: Date | string;
  /** Do we want to start by filtering the feed by a certain collection? */
  collection: string | null;
  /** Deduplicate already shown products */
  deduplicate: boolean;
  /** The page limit */
  limit: number;
  /** The page offset */
  offset: number;
  /** The length of collection pages, a scheduled release is always counted as a page  */
  pageLength: number;
  /** After which date do we filter out promoted "archived" products. Defaults to 2 months */
  promotedArchivedAfter?: Date | string;
};

export const defaultGetProductFeedOptions = () =>
  ({
    archivedAfter: PRODUCTS_ARCHIVED_FEED(),
    collection: null,
    deduplicate: false,
    limit: 1,
    offset: 0,
    pageLength: 50,
    promotedArchivedAfter: PROMOTED_ARCHIVED(),
  }) satisfies GetProductFeedOptions;

/**
 * Transforms a partial {@link GetProductFeedOptions} into a complete
 * {@link GetProductFeedOptions} with all defaults set and valid values.
 */
export function completeGetProductFeedOptions(
  options: Partial<GetProductFeedOptions> = {},
): GetProductFeedOptions {
  const defaults = defaultGetProductFeedOptions();

  options.collection ??= null;
  options.archivedAfter ??= defaults.archivedAfter;
  options.promotedArchivedAfter ??= defaults.promotedArchivedAfter;
  options.limit ??= defaults.limit;
  options.offset ??= defaults.offset;
  options.pageLength ??= defaults.pageLength;
  options.deduplicate ??= defaults.deduplicate;

  options.limit = Math.max(1, options.limit);
  options.offset = Math.max(0, options.offset);
  options.pageLength = Math.max(1, options.pageLength);

  return options as GetProductFeedOptions;
}

/**
 * Fetches a feed of products and looks based on the provided options.
 */
export async function getProductFeed(
  partialOptions?: Partial<GetProductFeedOptions>,
): Promise<(ProductSmall | Look)[]> {
  const options = completeGetProductFeedOptions(partialOptions);

  // Initialize the feed as an empty array.
  // This may be changed to an array of arrays for each page in the future.
  let feed: (ProductSmall | Look)[] = [];

  // Initialize variables for scheduled releases pagination
  let scheduledReleasesLimit = 0;
  let scheduledReleasesOffset = 0;

  // Check if a collection is specified in the options
  if (options.collection != null) {
    // Fetch the total page count for the specified collection
    const collectionPageCount = await getCollectionElementPageCount(options);

    // Check if the provided offset is less than the collection page count
    if (options.offset < collectionPageCount) {
      // Fetch and prepend collection items to the feed
      const collectionItems = await getCollectionElements(options);
      feed = feed.concat(collectionItems);
    }

    // If the offset is greater than or equal to the page count of the collection
    // pages we will need to fetch scheduled release pages
    if (options.offset >= collectionPageCount) {
      scheduledReleasesOffset = options.offset - collectionPageCount;
      scheduledReleasesLimit = options.limit;
    }

    // If the limit is greater than the page count of the collection pages we will
    // need to fetch the remainder of pages as scheduled releases
    if (options.limit > collectionPageCount) {
      scheduledReleasesOffset = 0;
      scheduledReleasesLimit = options.limit - collectionPageCount;
    }
  } else {
    // Nice, we don't need to deal with collection ordering offsets
    scheduledReleasesOffset = options.offset;
    scheduledReleasesLimit = options.limit;
  }

  // Check if there is a need to fetch scheduled releases
  if (scheduledReleasesLimit >= 1) {
    const scheduledReleases = await getPreviousScheduledReleases({
      ...options,
      // Override the options limit with the potentially modified `scheduledReleasesLimit` and `scheduledReleasesOffset`
      limit: scheduledReleasesLimit,
      offset: scheduledReleasesOffset,
    });

    // We don't actually care about dividing it into releases so let's just
    // combine all items into a single list and concatinate that to the end of the feed
    const scheduledReleaseItems = scheduledReleases.flatMap(({ items }) => items);
    feed = feed.concat(scheduledReleaseItems);
  }

  // Deduplicate items in feed, this may be useful if we sometime in the future want to
  // remove products which have already been shown in the categories section.
  if (options.deduplicate) {
    const seen = new Set();
    const dedupedFeed = [];

    for (const element of feed) {
      if ('products' in element) {
        dedupedFeed.push(element);
        continue;
      }

      if (seen.has(element.groupReference)) {
        continue;
      }

      seen.add(element.groupReference);
      dedupedFeed.push(element);
    }

    feed = dedupedFeed;
  }

  return feed;
}
