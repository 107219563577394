import React from 'react';

import Typography from 'components/ui/Typography';
import { cx } from 'utils';
import classes from './InputWrapper.module.css';

export type InputWrapperParentProps = {
  block?: boolean;
  error?: boolean;
  errorMessage?: string;
  label?: string;
  required?: boolean;
};

export type InputWrapperProps = {
  /** If the placeholder should be shown on top */
  active?: boolean;
  block?: boolean;
  children: React.ReactNode;
  error?: boolean;
  errorMessage?: string;
  for?: string;
  label?: string;
  required?: boolean;
};

const InputWrapper = (props: InputWrapperProps) => {
  return (
    <div className={cx(classes.wrapper, props.block && classes.block)}>
      <div className={cx(classes.inputWrapper, props.error && classes.error)}>
        {/* The place holder */}
        {props.label && (
          <Typography
            component="label"
            variant="p"
            htmlFor={props.for}
            className={cx(props.active && classes.active, classes.placeholder)}
          >
            {props.label}
            {props.required && ' *'}
          </Typography>
        )}
        {props.children}
      </div>
      <div className={classes.errorWrapper}>
        {props.error && (
          <Typography component="p" className={classes.errorText}>
            {props.errorMessage}
          </Typography>
        )}
      </div>
    </div>
  );
};
export default InputWrapper;
