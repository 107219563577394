import React from 'react';
import cx from 'classnames';

import styles from './Typography.module.css';

type HtmlTypographyElement = 'p' | 'h4' | 'h1' | 'h1-light' | 'h2' | 'h2-light' | 'h3' | 'b' | 'li';

export interface TypographyInterface extends React.HTMLProps<HTMLSpanElement> {
  align?: 'inherit' | 'left' | 'right' | 'center';
  block?: boolean;
  className?: string;
  component?: React.ElementType;
  variant?: HtmlTypographyElement;
}

/**
 * Renders a text with a variant as a <span> if nothing else is specificed.
 * The idea:
 * - Don't assume the document hierarchy, let the user set proper tags for SEO when applicable
 * - Use only one prop that infers the styling of the elemnent itself, the variant. Other
 *   props never alter the look of the text itself, just how it interacts with elements around it.
 * - All fontSizes are rems for easier resizing.
 */

const Typography: React.FC<TypographyInterface> = ({
  align,
  block,
  children,
  className,
  component,
  variant,
  ...rest
}) => {
  const Component = component || 'span';

  return (
    <Component
      className={cx(
        styles.root,
        styles[`align-${align}`],
        {
          [styles.block]: block,
        },
        variant && styles[variant],
        variant,
        className,
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Typography;
