import React from 'react';
import { DISABLE_ECOMMERCE } from 'consts';
import Link from 'next/link';

import Container from 'components/ui/Container';
import IconButton from 'components/ui/IconButton';
import Logo from 'components/ui/Logo';
import Typography from 'components/ui/Typography';
import AccountIcon from 'icons/account.svg';
import Burger from 'icons/burger.svg';
import ShoppingBagIcon from 'icons/shopping-bag.svg';
import { NavbarMenuItem } from 'types/Menu';
import { cx } from 'utils';
import classes from './Header.module.css';

export interface HeaderProps {
  amountOfCartItems?: React.ReactNode;
  color: 'white' | 'secondary';
  items: NavbarMenuItem[];
  onMenuClick?: () => void;
  onShoppingCartClick?: () => void;
  openShoppingCartDisabled?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  amountOfCartItems: notification,
  color,
  items,
  onMenuClick,
  onShoppingCartClick,
  openShoppingCartDisabled,
}) => {
  return (
    <header className={cx(classes.root, color === 'secondary' && classes.secondary)}>
      <Container padding="contained-regular">
        <div className={classes.content}>
          <div className={cx(classes.seg, classes.edgeSegments)}>
            <IconButton aria-label="open menu" onClick={onMenuClick}>
              <Burger />
            </IconButton>
            <div className={classes.items}>
              {items.map((item, i) => {
                return (
                  <Link key={i} href={item.href}>
                    <a>
                      <Typography component="p">{item.shortText ?? item.text}</Typography>
                    </a>
                  </Link>
                );
              })}
            </div>
          </div>
          <div className={cx(classes.seg, classes.logo)}>
            <Link href="/" passHref>
              <a>
                <Logo />
              </a>
            </Link>
          </div>
          <div className={cx(classes.seg, classes.edgeSegments)}>
            <IconButton square={19} aria-label="go to my pages" href="/mina-sidor">
              <AccountIcon />
            </IconButton>
            {!DISABLE_ECOMMERCE && (
              <IconButton
                disabled={openShoppingCartDisabled}
                square={19}
                aria-label="open shopping cart"
                onClick={onShoppingCartClick}
              >
                <ShoppingBagIcon className={classes.shoppingBagIcon} />
                {!!notification && <div className={classes.notification}>{notification}</div>}
              </IconButton>
            )}
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
