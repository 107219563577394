import React, { useEffect, useRef } from 'react';
import Link from 'next/link';

import Typography from 'components/ui/Typography';
import classes from './WelcomeBanner.module.css';

const LOCAL_STORAGE_KEY = 'welcomeBannerOpen';

export type WelcomeBannerProps = {
  date: Date;
};

function WelcomeBanner(props: WelcomeBannerProps) {
  const ref = useRef<HTMLDialogElement>(null);

  // Using `useLocalStorage` doesn't work in this case for some reason. I am guessing because of SSR
  useEffect(() => {
    const target = ref.current;
    if (target) {
      target.open = localStorage.getItem(LOCAL_STORAGE_KEY) !== 'false';

      const storageHandler = (event: StorageEvent) => {
        if (event.key === LOCAL_STORAGE_KEY) {
          target.open = event.newValue === 'true';
        }
      };

      const toggleHandler = () => {
        localStorage.setItem(LOCAL_STORAGE_KEY, target.open.toString());
      };

      addEventListener('storage', storageHandler);
      target.addEventListener('toggle', toggleHandler);

      return () => {
        removeEventListener('storage', storageHandler);
        target.removeEventListener('toggle', toggleHandler);
      };
    }
  }, [ref]);

  return (
    <details ref={ref} className={classes.root}>
      <summary className={classes.summary}>
        Nästa släpp{' '}
        {props.date.toLocaleTimeString(new Intl.Locale('SE'), {
          hour: '2-digit',
          hourCycle: 'h24',
          minute: '2-digit',
          second: undefined,
        })}
      </summary>
      <Typography className={classes.title} variant="h3">
        Välkommen till Arkivet online!
      </Typography>
      <Typography className={classes.subtitle} component="p">
        Vi släpper nya plagg dagligen och erbjuder ett kurerat urval av modernt Second Hand-mode.
        Följ din försäljning eller bli inlämningskund på{' '}
        <Link href="/mina-sidor">
          <a>Mina Sidor.</a>
        </Link>
      </Typography>
    </details>
  );
}

export default WelcomeBanner;
