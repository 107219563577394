import React from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';

import styles from './Backdrop.module.css';

export interface BackdropProps {
  className?: string;
  onClick?: () => void;
  open?: boolean;
}

const TRANSITION_KEY = 'backdrop-transition';

/**
 * Backdrop
 * Adds a semi-transparent overlay
 */
const Backdrop: React.FC<BackdropProps> = ({ className, onClick, open = true, ...rest }) => {
  return (
    <CSSTransition
      unmountOnExit
      mountOnEnter
      in={open}
      timeout={125}
      classNames={{
        enter: styles.hidden,
        enterActive: styles.visible,
        enterDone: styles.visible,
        exitActive: styles.hidden,
        exitDone: styles.exitDone,
      }}
    >
      <div
        key={TRANSITION_KEY}
        className={cx(styles.root, className)}
        onClick={onClick}
        {...rest}
      />
    </CSSTransition>
  );
};

export default Backdrop;
