import React from 'react';

import { cx } from 'utils';
import classes from './Spinner.module.css';

export type SpinnerProps = React.ComponentProps<'div'>;

const Spinner: React.FC<SpinnerProps> = props => {
  return (
    <div {...props} className={cx(props.className, classes.spinner)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
