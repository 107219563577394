import {
  SanityFileSource,
  SanityImageSource,
  SanityProjectDetails,
  tryGetFile,
} from '@sanity/asset-utils';
import imageUrlBuilder from '@sanity/image-url';
import { SanityClientLike, SanityModernClientLike } from '@sanity/image-url/lib/types/types';
import getConfig from 'next/config';

function replaceHostNameCacheUrl(url: string) {
  const {
    publicRuntimeConfig: {
      sanity: { proxyBaseName },
    },
  } = getConfig();

  if (proxyBaseName != null) {
    return url.replace(/^https?:\/\/[^/]+/, proxyBaseName);
  }

  return url;
}

class SanityUrlBuilder {
  private details: SanityClientLike | SanityProjectDetails | SanityModernClientLike | undefined;
  constructor(
    details: SanityClientLike | SanityProjectDetails | SanityModernClientLike | undefined,
  ) {
    this.details = details;
  }

  image(image: SanityImageSource) {
    // This is here so when a storybook image is returned correctly
    // When mocking in storybook
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((image as any)?.__type === 'mock-storybook') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const recursiveProxy = (): any =>
        new Proxy(
          {},
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            get(_, prop: any) {
              if (prop === 'url') {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return () => (image as any).url;
              }

              return () => recursiveProxy();
            },
          },
        );

      return recursiveProxy();
    }

    const {
      publicRuntimeConfig: {
        sanity: { proxyBaseName },
      },
    } = getConfig();

    const projectDetails = this.getProjectDetails();

    return imageUrlBuilder()
      .withOptions(projectDetails ? { baseUrl: proxyBaseName, ...projectDetails } : {})
      .image(image)
      .auto('format');
  }

  asset(source: SanityFileSource) {
    return new SanityAssetUrlBuilder(source, this.getProjectDetails());
  }

  private getProjectDetails() {
    const arg = this.details;

    if (arg == null) {
      return undefined;
    }

    if ('dataset' in arg) {
      return arg;
    }

    const { dataset, projectId } = 'clientConfig' in arg ? arg.clientConfig : arg.config();

    if (dataset == null || projectId == null) {
      return undefined;
    }

    return { dataset, projectId };
  }
}

class SanityAssetUrlBuilder {
  private source: SanityFileSource;
  private projectDetails: SanityProjectDetails | undefined;
  constructor(source: SanityFileSource, projectDetails: SanityProjectDetails | undefined) {
    this.source = source;
    this.projectDetails = projectDetails;
  }

  url() {
    const url = tryGetFile(this.source, this.projectDetails)?.asset?.url as unknown;

    if (typeof url !== 'string') {
      throw new Error("Couldn't load url");
    }

    return replaceHostNameCacheUrl(url);
  }
}

export default SanityUrlBuilder;
