import React from 'react';
import cx from 'classnames';

import Typography from 'components/ui/Typography';
import styles from './FieldSet.module.css';

export interface FieldSetProps extends React.HTMLProps<HTMLFieldSetElement> {
  description?: string | React.ReactNode;
}

/**
 * Fieldset is used to bring semantics, accessibility and some basic styling to inputs or sets of inputs.
 *
 * @param className "className"
 * @param disabled disable entire FieldSet
 * @param description Bolded text rendered as a <legend>.
 */
const FieldSet: React.FC<FieldSetProps> = ({
  children,
  className,
  description,
  disabled = false,
  ...props
}) => {
  return (
    <fieldset
      disabled={disabled}
      aria-disabled={disabled}
      className={cx(styles.root, className)}
      {...props}
    >
      {description && (
        <Typography variant="h3" component="legend">
          {description}
        </Typography>
      )}
      {children}
    </fieldset>
  );
};

export default FieldSet;
