import React, { useMemo } from 'react';
import Cookies from 'js-cookie';
import Link from 'next/link';

import Logo from 'components/ui/Logo';
import { FooterMenuItem } from 'types/Menu';
import styles from './Footer.module.css';

export interface FooterProps {
  links: FooterMenuItem[];
}

const Footer: React.FC<FooterProps> = ({ links }) => {
  const categories = useMemo(() => {
    const categories: Record<string, FooterMenuItem[]> = {};

    for (const link of links) {
      // eslint-disable-next-line
      categories[link.category] ??= [];
      categories[link.category].push(link);
    }

    return categories;
  }, [links]);

  return (
    <footer className={styles.footer}>
      <Link href="/">
        <Logo variant="symbol" className={styles.logo} />
      </Link>
      <section className={styles.categories}>
        {Object.keys(categories).map(category => (
          <section key={category} className={styles.category}>
            <h2>{category}</h2>
            <ul className={styles.categoryLinks}>
              {category === 'KUNDSERVICE' && (
                <li className={styles.item}>
                  <a
                    onClick={() => {
                      if (window.acceptCookieTokenName != null) {
                        Cookies.remove(window.acceptCookieTokenName);
                      }
                      window.location.reload();
                    }}
                    className={styles.link}
                  >
                    Hantera cookies
                  </a>
                </li>
              )}
              {categories[category].map((link, index) => (
                <li key={index} className={styles.item}>
                  <Link passHref href={link.href} key={link.text}>
                    <a className={styles.link}>{link.text}</a>
                  </Link>
                </li>
              ))}
            </ul>
          </section>
        ))}
      </section>
    </footer>
  );
};

export default Footer;
