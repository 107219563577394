import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import dynamic from 'next/dynamic';

import _ from 'lib/lodash';

const ActualCookieBar = dynamic(() => import('./ActualCookiePopup'), {
  ssr: false,
});

export type CookieProps<T extends FixedLengthArray<string, number>> = {
  cookieCategories: T;
};

interface FixedLengthArray<T, N extends number> extends Array<T> {
  0: T;
  length: N;
}

const CookiePopup = <
  V extends string,
  T extends FixedLengthArray<{ id: V; title: string }, number>,
>(props: {
  categories: T;
  onAccept: (accepted: {
    [key in keyof T as key extends number ? T[key]['id'] : never]: boolean;
  }) => void;
  // Cookiepolicy version
  version: string;
}): JSX.Element | null => {
  const cookieAcceptId = `accepted-cookies-${props.categories.map(v => v.id).join('-')}-${
    props.version
  }`;

  useEffect(() => {
    // not very reacty way to do this, but i don't want to use a context due to all the generics, and i'm not sure how you solve this otherwise
    window.acceptCookieTokenName = cookieAcceptId;
  }, [cookieAcceptId]);

  const [showCookieBar, setShowCookieBar] = useState(false);

  useEffect(() => {
    const storedStateRaw = Cookies.get(cookieAcceptId);

    if (storedStateRaw == null) {
      return void setShowCookieBar(true);
    }

    try {
      const storedState = JSON.parse(storedStateRaw) as unknown;

      if (!_.isObject(storedState)) {
        throw 'incorrect type';
      }

      if (props.categories.some(v => !(v.id in storedState))) {
        throw 'missing key';
      }

      if (Object.values(storedState).some(v => typeof v !== 'boolean')) {
        throw 'not boolean';
      }

      if (Object.keys(storedState).length !== props.categories.length) {
        throw 'too many keys';
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      props.onAccept(storedState as any);
    } catch (e) {
      setShowCookieBar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showCookieBar) {
    return (
      <ActualCookieBar
        categories={props.categories}
        onAccept={v => {
          setShowCookieBar(false);
          Cookies.set(cookieAcceptId, JSON.stringify(v), {
            expires: new Date(new Date().getTime() + 86400 * 1000 * 10), // 10 år senare
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          props.onAccept(v as any);
        }}
      />
    );
  }

  return null;
};

export default CookiePopup;
