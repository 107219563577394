import { NavbarMenuItem } from 'types/Menu';
import { sanityClient } from '../client';
import { preProgrammedPages } from './preDefinedPages';

/**
 * Fetches the menu items from sanity
 */
export default async function getNavbarMenu(): Promise<NavbarMenuItem[]> {
  try {
    const data = await sanityClient.fetch<Record<string, unknown>[]>(
      `*[_type == "navbarMenu"] | order(orderRank) { position, text, shortText, typeOfPage, preProgrammedPage, "sanitySlug": page->slug.current }`,
    );

    return data
      .map((v): NavbarMenuItem | null => {
        switch (v.typeOfPage) {
          case 'next-managed': {
            const { preProgrammedPage = 'NEVER' } = v as {
              preProgrammedPage: string | undefined;
            };

            if (!(preProgrammedPage in preProgrammedPages)) {
              return null;
            }

            return {
              href: preProgrammedPages[preProgrammedPage as keyof typeof preProgrammedPages],
              position: v.position as NavbarMenuItem['position'],
              shortText: v.shortText as string | undefined,
              text: v.text as string,
            };
          }
          case 'sanity-managed':
            if (typeof v.sanitySlug !== 'string') {
              return null;
            }

            return {
              href: `/sida/${v.sanitySlug}`,
              position: v.position as NavbarMenuItem['position'],
              shortText: v.shortText as string | undefined,
              text: v.text as string,
            };
          default:
            return null;
        }
      })
      .filter((v): v is NavbarMenuItem => v !== null);
  } catch {
    return [];
  }
}
