import { Gtag } from 'lib/gtag';
import { CartProduct, Product, ProductSmall } from 'types/Product';
import extractGroupReference from 'utils/extractGroupReference';

function productToGtagItem(
  product: Product | ProductSmall | CartProduct,
  index?: number,
): Gtag.Item {
  // Check if this is a CartProduct
  if ('item' in product) {
    return {
      index,
      item_brand: product.product.brand,
      item_id: extractGroupReference(product.item.reference),
      item_name: product.product.name,
      price: product.item.price,
    };
  }

  return {
    index,
    item_brand: product.brand,
    item_id: product.groupReference,
    item_name: product.name,
    price: product.price,
  };
}

export default productToGtagItem;
