import React, { useId, useMemo, useState } from 'react';
import { Extensions } from 'bananas-commerce';
import { CURRENCY } from 'consts';
import phone from 'phone';

import Button from 'components/ui/Button';
import { Input } from 'components/ui/Inputs';
import FieldSet from 'components/ui/Inputs/FieldSet';
import { useCart } from 'contexts/CartContext';
import { useCookieAcceptState } from 'contexts/CookieContext';
import gtag from 'lib/gtag';
import { productToGtagItem } from 'utils';
import validate from 'utils/validate';
import { useCheckoutFormValues, useCheckoutPage } from '../../CheckoutContext';
import classes from './CheckoutForm.module.css';

function formatPhone(value: string): string {
  const formattedPhone = phone(value, { country: 'SE' });

  if (formattedPhone.isValid) {
    return formattedPhone.phoneNumber;
  }
  return value;
}

type ValidateResult = {
  [key in keyof {
    [key in keyof Extensions.IngridConfirm.Args['customer']]-?: string;
  }]: string | undefined;
};

function validateCustomerFormData(
  customer: Extensions.IngridConfirm.Args['customer'],
): ValidateResult {
  const rv = (field?: string) => field && field.trim().length !== 0;

  return {
    careOf: undefined,
    city: rv(customer.city) ? undefined : 'Du måste ange en stad',
    companyName: undefined,
    countryCode: undefined,
    email: (() => {
      if (!rv(customer.email)) {
        return 'Du måste ange en e-post';
      }

      if (!validate.email(customer.email)) {
        return 'Du måste ange en giltig e-post';
      }

      return undefined;
    })(),
    familyName: rv(customer.familyName) ? undefined : 'Du måste ange ett förnamn',
    givenName: rv(customer.givenName) ? undefined : 'Du måste ange ett förnamn',
    phone:
      customer.phone &&
      (() => {
        if (!rv(customer.phone)) {
          return 'Du måste ange ett telefonnummer';
        }

        if (!validate.phone(customer.phone, 'SE')) {
          return 'Du måste ange ett giltig telefonnummer';
        }

        return undefined;
      })(),
    postalCode: rv(customer.postalCode) ? undefined : 'Du måste ange ett postnummer',
    region: undefined,
    streetAddress: rv(customer.streetAddress) ? undefined : 'Du måste ange en address',
    streetAddress2: undefined,
  };
}

function getAutoFillProps(name: string, id: string) {
  return {
    autoComplete: name,
    id: `${name}-${id}`,
    name: name,
  };
}

function CheckoutForm(): JSX.Element {
  const [args, setArgs] = useCheckoutFormValues();
  const id = useId();

  const customer = useMemo(
    (): Extensions.IngridConfirm.Args['customer'] =>
      args?.customer ?? {
        city: '',
        countryCode: 'SE',
        email: '',
        familyName: '',
        givenName: '',
        postalCode: '',
        streetAddress: '',
      },
    [args],
  );

  const [errors, setErrors] = useState<Partial<ReturnType<typeof validateCustomerFormData>>>({});
  const isInvalid = (errors: Record<string, string | undefined>) =>
    Object.values(errors).some(v => typeof v === 'string');

  const getPropsInput = (key: keyof Extensions.IngridConfirm.Args['customer']) => ({
    error: errors[key] != null,
    errorMessage: errors[key],
    onBlur: () => {
      setErrors({ ...errors, [key]: validateCustomerFormData(customer)[key] });
    },
    onChange: (e: React.ChangeEvent) => {
      const newCustomer = {
        ...customer,
        [key]: 'value' in e.currentTarget ? e.currentTarget.value : undefined,
      };
      setArgs({ ...args, customer: newCustomer });
    },
    value: customer[key] ?? '',
  });

  const [, setPage] = useCheckoutPage();

  const { products } = useCart();

  const cookieAccept = useCookieAcceptState();

  return (
    <div className={classes.wrapper}>
      <form
        onSubmit={e => {
          e.preventDefault();
          const newErrors = validateCustomerFormData(customer);
          setErrors(newErrors);

          if (isInvalid(newErrors)) {
            return;
          }

          setArgs({
            ...args,
            customer: {
              ...customer,
              phone: customer.phone && formatPhone(customer.phone),
            },
          });

          if (cookieAccept.analytics) {
            gtag('event', 'add_payment_info', {
              currency: CURRENCY,
              items: products.map(productToGtagItem),
              value: products.reduce((a, v) => a + v.item.price, 0),
            });
          }

          setPage('payment');
        }}
      >
        <FieldSet description="Kontaktuppgifter">
          <Input
            label="Förnamn"
            required
            block
            {...getAutoFillProps('given-name', id)}
            {...getPropsInput('givenName')}
          />
          <Input
            label="Efternamn"
            required
            block
            {...getAutoFillProps('family-name', id)}
            {...getPropsInput('familyName')}
          />
          <Input
            label="E-post"
            required
            block
            {...getAutoFillProps('email', id)}
            {...getPropsInput('email')}
          />
          {(() => {
            const props = getPropsInput('phone');
            return (
              <Input
                label="Telefon"
                required
                block
                {...getAutoFillProps('tel', id)}
                {...props}
                onBlur={() => {
                  props.onBlur();
                  setArgs({
                    ...args,
                    customer: {
                      ...customer,
                      phone: formatPhone(props.value) || undefined,
                    },
                  });
                }}
              />
            );
          })()}
        </FieldSet>
        <FieldSet description="Leveransaddress">
          <Input
            label="Address"
            required
            block
            {...getAutoFillProps('street-address', id)}
            {...getPropsInput('streetAddress')}
          />
          <Input label="C/O" block {...getPropsInput('streetAddress2')} />
          <Input
            label="Postnummer"
            disabled
            required
            block
            value={customer.postalCode.replace(/^(\d{3})(\d{2})$/, '$1 $2')}
          />
          <Input
            label="Stad"
            required
            block
            {...getAutoFillProps('address-level2', id)}
            {...getPropsInput('city')}
          />
        </FieldSet>
        <div className={classes.buttonCont}>
          <Button type="submit" disabled={isInvalid(errors)} className={classes.button} size="full">
            Fortsätt till betalningsalternativ
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CheckoutForm;
