import React from 'react';

import Clickable, { ClickableProps } from 'components/ui/Clickable';
import { cx } from 'utils';
import Typography from '../Typography';
import classes from './Button.module.css';

export type ButtonProps = {
  block?: boolean;
  className?: string;
  color?: 'primary' | 'secondary' | 'tertiary';
  disabled?: boolean;
  size?: 'small' | 'wider' | 'full';
} & React.ComponentPropsWithoutRef<'button' | 'a'> &
  ClickableProps;

const Button: React.FC<ButtonProps> = React.forwardRef<
  HTMLAnchorElement & HTMLButtonElement,
  ButtonProps
>(({ block, children, className, color = 'primary', disabled, size, ...rest }, ref) => {
  return (
    <Clickable
      ref={ref}
      className={cx(
        classes.root,
        classes[`color-${color}`],
        classes[`size-${size}`],
        { [classes.block]: block },
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      {typeof children === 'string' ? (
        <Typography variant="b" component="p">
          {children}
        </Typography>
      ) : (
        children
      )}
    </Clickable>
  );
});

Button.displayName = 'Button';

export default Button;
