import { Brand } from 'types/Brand';
import { sanityClient } from '../client';

export default async function getBrand(slug: string): Promise<Brand | null> {
  try {
    // Hidden is optional because the schema because of backwards compatibility
    const data = await sanityClient.fetch<(Omit<Brand, 'hidden'> & { hidden?: boolean }) | null>(
      `*[_type == "brand" && slug.current == $slug][0] { name, description, "slug": slug.current, hidden }`,
      { slug },
    );

    if (!data) {
      return null;
    }

    return {
      hidden: false,
      ...data,
    };
  } catch {
    return null;
  }
}
