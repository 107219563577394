/*
 * Runs mounted script nodes nested in a specific dom node.
 * This function is copy pasted and slightly modified from Ingrids website
 *
 * @param node - Element to recursively look through and run script tags
 */
export function ingridReplaceScriptNode(node: Element) {
  const isScriptNode = (node: Element): node is HTMLScriptElement => {
    return node.tagName === 'SCRIPT';
  };

  const isExternalScript = (node: HTMLScriptElement) => {
    return !!node.src && node.src !== '';
  };

  const cloneScriptNode = (node: HTMLElement) => {
    const script = document.createElement('script');
    script.text = node.innerHTML;
    for (let i = node.attributes.length - 1; i >= 0; i--) {
      script.setAttribute(node.attributes[i].name, node.attributes[i].value);
    }
    return script;
  };

  if (isScriptNode(node) && !isExternalScript(node)) {
    // Assume that a parentNode exist
    node.parentNode!.replaceChild(cloneScriptNode(node), node);
  } else {
    let i = 0;
    const children = node.children;
    while (i < children.length) {
      ingridReplaceScriptNode(children[i++]);
    }
  }

  return node;
}
