/* eslint-disable @typescript-eslint/no-explicit-any */

import { Look } from 'types/Look';
import { ScheduledRelease } from 'types/ScheduledRelease';
import { sanityClient } from '../client';
import elementToProductSmall from './helper/elementsToProducts';

export default async function getNextScheduledReleases(offset = 0): Promise<ScheduledRelease[]> {
  try {
    // A lot of any here, but it's we don't know the types either
    const data = await sanityClient.fetch<
      {
        elements: unknown[];
        releaseAt: string;
      }[]
    >(
      `*[
        _type == "scheduledRelease" &&
        dateTime(releaseAt) > dateTime(now())
      ] | order(dateTime(releaseAt) asc)[0..$offset] {
      releaseAt,
      previewCount,
      elements[]->{
        ...,
        brand->,
        products[]{..., product->{..., brand->,"videos": media[]->{...}}},
      }
    }`,
      { offset },
    );

    return data.map(release => {
      const final = release.elements
        .map((v: any) => {
          if (v._type === 'product') {
            return elementToProductSmall(v);
          }
          const look: Look = {
            image: v.image,
            name: v.name,
            products: v.products
              .map((vv: any) => {
                const element = elementToProductSmall(vv.product);

                if (!element) {
                  return undefined;
                }

                return {
                  product: element,
                  x: vv.x / 100,
                  y: vv.y / 100,
                };
              })
              .filter((v: any) => typeof v !== 'undefined'),
          };

          return look;
        })
        .filter((v): v is NonNullable<typeof v> => v !== null);

      return { items: final, releaseAt: release.releaseAt };
    });
  } catch {
    return [];
  }
}
