import getConfig from 'next/config';
import { createClient } from 'next-sanity';

const {
  publicRuntimeConfig: { sanity },
} = getConfig();

export const sanityClient = createClient({
  apiVersion: '2022-03-25',
  dataset: sanity.dataset,
  projectId: sanity.projectId,
  useCdn: sanity.useCdn ?? false,
});
