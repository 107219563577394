import React, { useContext } from 'react';
import { Extensions } from 'bananas-commerce';

export type CheckoutView = 'shipping' | 'form' | 'payment';
type CheckoutState = {
  formValues: Extensions.IngridConfirm.Args | null;
  page: CheckoutView;
  setFormValues: (
    v:
      | Extensions.IngridConfirm.Args
      | ((v: Extensions.IngridConfirm.Args | null) => Extensions.IngridConfirm.Args),
  ) => void;
  setPage: (v: CheckoutView) => void;
  setShippingPrice: (v: number) => void;
  setShippingReference: (v: string) => void;
  shippingPrice: number | null;
  shippingReference: string | null;
};

export const CheckoutStateContext = React.createContext<CheckoutState>({
  formValues: null,
  page: 'form',
  setFormValues: v => void v,
  setPage: v => void v,
  setShippingPrice: v => void v,
  setShippingReference: v => void v,
  shippingPrice: null,
  shippingReference: null,
});

export function useCheckoutPage() {
  const { page, setPage } = useContext(CheckoutStateContext);

  return [page, setPage] as const;
}

export function useCheckoutShippingReference() {
  const { setShippingReference, shippingReference } = useContext(CheckoutStateContext);

  return [shippingReference, setShippingReference] as const;
}

export function useCheckoutFormValues() {
  const { formValues, setFormValues } = useContext(CheckoutStateContext);

  return [formValues, setFormValues] as const;
}
export function useCheckoutShippingPrice() {
  const { setShippingPrice, shippingPrice } = useContext(CheckoutStateContext);

  return [shippingPrice, setShippingPrice] as const;
}
