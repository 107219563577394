import React from 'react';

import ArrowBack from 'icons/arrow-back.svg';
import { cx } from 'utils';
import Typography from '../Typography';
import classes from './ArrowButton.module.css';

export type ArrowButtonProps = {
  text: string;
} & React.ComponentProps<'div'>;

const ArrowButton: React.FC<ArrowButtonProps> = ({ text, ...props }) => {
  return (
    <div {...props} className={cx(props.className, classes.arrowButton)}>
      <ArrowBack />
      <Typography component="p" variant="b">
        {text}
      </Typography>
    </div>
  );
};

export default ArrowButton;
