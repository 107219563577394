/* eslint-disable @typescript-eslint/no-explicit-any */

import { SanityFileSource, SanityImageSource } from '@sanity/asset-utils';

import { Media } from 'types/Media';
import { ProductSmall } from 'types/Product';

export default function elementToProductSmall(element: any): ProductSmall | null {
  try {
    return {
      brand: element.brand.name,
      condition: element.condition,
      groupReference: element.groupReference,
      media:
        parseMedia(element) ??
        (() => {
          throw new Error("Couldn't parse media");
        })(),
      name: element.name,
      price: element.price,
      slug: element.slug.current,
    };
  } catch (e) {
    // Oh no, the parsing couldn't be done
    // In that case just return null, something has changed in sanity
    return null;
  }
}

export function parseMedia(element: any): Media | null {
  const media = element.media[0];

  if (media._type === 'image') {
    return {
      source: media as SanityImageSource,
      type: 'image',
    };
  }
  return {
    thumbnail: media.thumbnail as SanityImageSource,
    type: 'video',
    video: media.video as SanityFileSource,
  };
}
