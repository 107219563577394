import { Nullish } from 'types/helpers';

export const UNKNOWN_ARTICLE = 'CA001';
export const OUT_OF_STOCK = 'IN002';
export const NO_STOCK = 'IN001';
export const HIGH_DEMAND = 'TR001';
export const UNKNOWN_ERROR = 'NOFUN'; // Frontend specific error
const KnownResultCodes = {
  HIGH_DEMAND: HIGH_DEMAND,
  NO_STOCK: NO_STOCK,
  OUT_OF_STOCK: OUT_OF_STOCK,
  UNKNOWN_ARTICLE: UNKNOWN_ARTICLE,
  UNKNOWN_ERROR: UNKNOWN_ERROR,
} as const;
export type ResultLabel = keyof typeof KnownResultCodes;
export type ResultCode = (typeof KnownResultCodes)[ResultLabel];

type ItemResult = {
  codes: ResultCode[];
  label: Nullish<ResultLabel>;
};

export type ItemError = ItemResult;
export type ItemWarning = ItemResult;

function isLabelKnown(label: string): label is ResultLabel {
  return Object.hasOwn(KnownResultCodes, label);
}

function isCodeKnown(code: string): code is ResultCode {
  return (Object.values(KnownResultCodes) as string[]).includes(code);
}

function parseItemResult(kind: 'warning' | 'error', result: string): ItemResult {
  const match = result.match(/(\w+)\[((?:\w+,?)+)\]/);

  if (match == null) {
    console.warn(`could not parse item result:`, result);
  }

  const rawLabel = match?.[1] ?? 'UNKNOWN_ERROR';
  const codes = match?.[2]?.split(',') ?? [];

  let label: ResultLabel | null = null;
  if (isLabelKnown(rawLabel)) {
    label = rawLabel;
  } else {
    console.warn(`unknown ${kind}:`, result);
  }

  const knownCodes: ResultCode[] = codes.filter((code): code is ResultCode => {
    const codeIsKnown = isCodeKnown(code);
    if (!codeIsKnown) {
      console.warn(`unknown ${kind} code:`, code);
    }
    return codeIsKnown;
  });

  // NOTE: We ignore warnings we don't know about, since it's just a warning anyway.
  //       Errors however, errors are fatal.
  if (kind === 'error' && knownCodes.length === 0) {
    knownCodes.push(UNKNOWN_ERROR);
  }
  return {
    codes: knownCodes,
    label,
  };
}

export function parseItemError(error: string): ItemError {
  return parseItemResult('error', error);
}

export function parseItemWarning(warning: string): ItemWarning {
  return parseItemResult('warning', warning);
}
