import { AnswerGroup } from 'types/FAQ';
import { sanityClient } from '../client';

export default async function getFAQ(): Promise<AnswerGroup[]> {
  try {
    const data = await sanityClient.fetch<AnswerGroup[]>(
      `*[_type == "faqGroup"] | order(orderRank) { ... }`,
    );

    return data;
  } catch {
    return [];
  }
}
