// To keep bundle size down, tree shake the required functions and then add them to the `_` object
import clone from 'lodash/clone';
import isObject from 'lodash/isObject';

const _ = {
  clone,
  isObject,
};

export default _;
