import PromisePool from '@supercharge/promise-pool';
import { Client, Extensions } from 'bananas-commerce';

import chunk from 'utils/chunk';

/**
 * Send multiple availability requests and combines them in case that the request becomes to large
 *
 * @param bcom - For the explicity so the end user understands that this function makes fetch calls
 * @param groupReferences - The group reference to fetch availability on
 *
 * @returns A faked response generated by combining response of chunked requests
 */
async function chunkAvailability(
  bcom: Client<[Extensions.Pos]>,
  groupReferences: string[],
): Promise<Extensions.Availability.Result> {
  const { results: responses } = await PromisePool.withConcurrency(10)
    .for(chunk(groupReferences, 150))
    .process(groupReference => bcom.pos.availability({ groupReference }));

  const errorResponse = responses.find(res => res._type !== 'success');

  if (errorResponse) {
    return errorResponse;
  }

  return {
    _type: 'success',
    groups: responses.flatMap(res => {
      // Type safety so we automatically notice if the responses change, this switch is covered by the find call further up
      switch (res._type) {
        case 'success': {
          return (res.groups as typeof res.groups | undefined) ?? [];
        }
        case 'internal-error': {
          return [];
        }
      }
    }),
  };
}

export default chunkAvailability;
