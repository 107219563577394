import { useEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';

function save() {
  sessionStorage.setItem('__app_scroll', window.scrollY.toString());
}

function restore() {
  const top = parseFloat(sessionStorage.getItem('__app_scroll') ?? '0');
  // We apparently need to wait for the initial render of the product cards until scrolling because
  // of a content-shift which sucks, and there is a better solution where we pre-calculate the height
  // of the product cards container or have graybox placeholder cars so there is no content-shift and
  // scroll can be instant.
  setTimeout(() => window.scrollBy({ top }), 10);
}

function useScrollRestore() {
  const router = useRouter();
  const [shouldScrollRestore, setShouldScrollRestore] = useState(false);

  useEffect(() => {
    if (shouldScrollRestore && router.route === '/') {
      restore();
    }
  }, [router.route, shouldScrollRestore]);

  useEffect(() => {
    if (!('scrollRestoration' in window.history)) return;
    window.history.scrollRestoration = 'manual';

    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (router.route === '/') {
        save();
        delete event['returnValue'];
      }
    };

    const onRouteChangeStart = () => {
      if (router.route === '/') {
        save();
      }
    };

    const onRouteChangeComplete = () => {
      setShouldScrollRestore(false);
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeComplete);
    Router.beforePopState(() => {
      setShouldScrollRestore(true);
      return true;
    });

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
      Router.events.off('routeChangeStart', onRouteChangeStart);
      Router.events.off('routeChangeComplete', onRouteChangeComplete);
      Router.beforePopState(() => true);
    };
  }, [router, shouldScrollRestore]);
}

export default useScrollRestore;
