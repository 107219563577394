import { Collection } from 'types/Collection';
import { sanityClient } from '../client';

export default async function getCollections(): Promise<Collection[]> {
  try {
    return await sanityClient.fetch<Collection[]>(
      `*[_type == "collection"] { name, image, "slug": slug.current }`,
    );
  } catch {
    return [];
  }
}
