import React, { useMemo } from 'react';

import Clickable from 'components/ui/Clickable';
import Label from 'components/ui/Label';
import Typography from 'components/ui/Typography';
import Close from 'icons/close.svg';
import { cx } from 'utils';
import { HIGH_DEMAND, NO_STOCK, OUT_OF_STOCK, ResultCode } from 'utils/errors';
import formatPrice from 'utils/formatPrice';
import classes from './ShoppingCartItem.module.css';

export interface ShoppingCartItemProps {
  brand: string;
  image: string;
  label?: ResultCode;
  name: string;
  onRemove?: () => void;
  price: number;
}

const ShoppingCartItem: React.FC<ShoppingCartItemProps> = ({
  brand,
  image,
  label,
  name,
  onRemove,
  price,
}) => {
  const labelText = useMemo(() => {
    switch (label) {
      case HIGH_DEMAND:
        return 'Många har denna i sin varukorg';
      case NO_STOCK:
      case OUT_OF_STOCK:
        return 'Slutsåld';
    }
  }, [label]);

  const color = useMemo(() => {
    switch (label) {
      case HIGH_DEMAND:
        return 'yellow';
      case NO_STOCK:
      case OUT_OF_STOCK:
        return 'red';
    }
  }, [label]);

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <div className={classes.image} style={{ backgroundImage: `url("${image}")` }}></div>
        <div className={cx(classes.middle, classes.pushApart)}>
          <div>
            <Typography className={cx(classes.brand)} component="h2">
              {brand}
            </Typography>
            <Typography className={classes.name} component="p">
              {name}
            </Typography>
          </div>
          {label && (
            <div className={classes.label}>
              <Label color={color}>{labelText}</Label>
            </div>
          )}
        </div>
        <div className={cx(classes.right, classes.pushApart)}>
          <Typography component="p" variant="b">
            {formatPrice(price)}:-
          </Typography>
          <Clickable onClick={onRemove} className={classes.removeButton}>
            <Typography className={classes.removeButtonText} component="p">
              Ta bort
            </Typography>
            <Close className={classes.xIcon} />
          </Clickable>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartItem;
