import React from 'react';
import { useRouter } from 'next/router';

const useUrlId = (id: string): [() => void, () => void] => {
  const router = useRouter();
  const { asPath, pathname, query } = router;

  const apply = React.useCallback(() => {
    router.replace(`#${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, query, id]);

  const remove = React.useCallback(() => {
    router.replace(asPath.replace(new RegExp(`#${id}$`), ''), undefined, {
      scroll: false,
      shallow: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, query, id]);

  return [apply, remove];
};

export default useUrlId;
