import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Extensions } from 'bananas-commerce';
import { CURRENCY } from 'consts';
import dynamic from 'next/dynamic';

import Accordion from 'components/ui/Accordion';
import ArrowButton from 'components/ui/ArrowButton';
import Button from 'components/ui/Button';
import Spinner from 'components/ui/Spinner';
import Typography from 'components/ui/Typography';
import { useCart } from 'contexts/CartContext';
import { useCookieAcceptState } from 'contexts/CookieContext';
import { useHeight, useIsMount } from 'hooks';
import gtag from 'lib/gtag';
import { useCartVerification } from 'queries';
import { Tuple } from 'types/Tuple';
import { cx } from 'utils';
import productToGtagItem from 'utils/productToGtagItem';
import classes from './CheckoutContent.module.css';
import { CheckoutStateContext, CheckoutView, useCheckoutPage } from './CheckoutContext';
import Items from './Items';
import CheckoutForm from './pages/CheckoutForm';
import IngridPage from './pages/Ingrid';

export type CheckoutContentProps = { id: string };

const AdyenCheckout = dynamic(() => import('./pages/AdyenCheckout'), {
  loading: () => (
    <div className={classes.spinnerCont}>
      <Spinner />
    </div>
  ),
  ssr: false,
});

const CheckoutContent: React.FC<CheckoutContentProps> = props => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<CheckoutView>('shipping');
  const [formValues, setFormValues] = useState<Extensions.IngridConfirm.Args | null>(null);
  const [shippingReference, setShippingReference] = useState<string | null>(null);
  const [shippingPrice, setShippingPrice] = useState<number | null>(null);

  const {
    cartDrawerShown: [cartOpen],
    products,
    removeItem,
  } = useCart();

  const { data: cart, refetch } = useCartVerification(
    {
      references: products.map(product => product.item.reference),
    },
    {
      // This useQuery is completely controlled by setting queryClient and using the refetch function
      // This too prevent excess fetching
      enabled: !!cartOpen && products.length !== 0,
      refetchOnWindowFocus: false,
      staleTime: 10_000,
    },
  );

  // All of this is super ugly hacks to get the frontend to only fetch cart when its absolutely necessary
  const shouldRefetchOnPageChange = useRef(true);
  useEffect(() => {
    shouldRefetchOnPageChange.current = false;
    setPage('shipping');
  }, [products]);

  useEffect(() => {
    wrapperRef.current?.scrollTo(0, 0);
  }, [page]);

  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount && shouldRefetchOnPageChange.current) {
      refetch();
    }
    shouldRefetchOnPageChange.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const cookieAccept = useCookieAcceptState();
  const queryClient = useQueryClient();

  return (
    <CheckoutStateContext.Provider
      value={{
        formValues,
        page,
        setFormValues,
        setPage,
        setShippingPrice,
        setShippingReference,
        shippingPrice,
        shippingReference,
      }}
    >
      <div className={classes.wrapper} ref={wrapperRef} id={props.id}>
        <div className={classes.top}>
          <Accordion shown={page !== 'shipping'}>
            <ArrowButton
              onClick={() => (page === 'payment' ? setPage('form') : setPage('shipping'))}
              className={classes.arrowButton}
              text={page === 'payment' ? 'Kontakt & leveransadress' : 'Leveransalternativ'}
            />
          </Accordion>
          <Items
            products={products}
            onRemove={async cartProduct => {
              removeItem(cartProduct);

              if (cookieAccept.analytics) {
                gtag('event', 'remove_from_cart', {
                  currency: CURRENCY,
                  items: [productToGtagItem(cartProduct)],
                  value: cartProduct.item.price,
                });
              }

              try {
                const keyOpts = {
                  references: products
                    .map(product => product.item.reference)
                    .filter(ref => ref !== cartProduct.item.reference),
                };

                if (keyOpts.references.length === 0) {
                  return;
                }

                const data = await useCartVerification.fetchData(keyOpts);
                queryClient.setQueryData(useCartVerification.queryKey(keyOpts), data);
              } catch (e) {
                // Handle this somehow
                console.error(e);
              }
            }}
            cartProducts={cart}
          />
        </div>

        {!products.length && (
          // This component will be placed above the slideshow
          // To prevent the slide show being unmounted
          // :^) the ingrid shipment thing needs to be mounted at all times
          <div className={classes.spinnerCont}>
            <Typography>Din varukorg är tom</Typography>
          </div>
        )}
        {/* Due to how the ingrid widget works this component needs to be mounted at all times. */}
        <PagesSlideshow />
      </div>
    </CheckoutStateContext.Provider>
  );
};

function PagesSlideshow() {
  const pages = new Array(3).fill(0).map(() => {
    const [ref, height] = useHeight();
    return { height, ref };
  }) as Tuple<PageHeightRef, 3>;

  const [page] = useCheckoutPage();

  type PageHeightRef = { height: number; ref: React.RefObject<HTMLDivElement> };

  const currentPageIndex = useMemo(() => {
    switch (page) {
      case 'form':
        return 1;
      case 'shipping':
        return 0;
      case 'payment':
        return 2;
    }
  }, [page]);

  const { products, removeItem } = useCart();
  const cartVerification = useCartVerification({
    references: products.map(v => v.item.reference),
  });

  return (
    <div className={cx(classes.pageWrapper)}>
      {(() => {
        if (
          cartVerification.data != null &&
          Object.values(cartVerification.data).some(v => v.error)
        ) {
          return (
            <div className={classes.cartErrorWrapper}>
              <div className={classes.spinnerContainer}>
                <Typography className={classes.cartErrorText} align="center" component="p">
                  Några varor i din varukorg kan inte köpas längre
                </Typography>
                <Button
                  block
                  color="primary"
                  onClick={() => {
                    for (const { error, reference } of Object.values(cartVerification.data)) {
                      if (error != null) {
                        removeItem(reference);
                      }
                    }
                  }}
                >
                  Ta bort ogilltiga varor
                </Button>
              </div>
            </div>
          );
        }
      })()}
      <div
        className={classes.pages}
        style={{
          height: `${pages[currentPageIndex].height}px`,
          transform: `translateX(-${(currentPageIndex / 3) * 100}%)`,
        }}
      >
        <div className={classes.page} ref={pages[0].ref}>
          <IngridPage />
        </div>
        <div className={classes.page} ref={pages[1].ref}>
          <CheckoutForm />
        </div>
        <div className={classes.page} ref={pages[2].ref}>
          <AdyenCheckout />
        </div>
      </div>
    </div>
  );
}

export default CheckoutContent;
