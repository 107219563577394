import { Look } from 'types/Look';
import { Product, ProductSmall } from 'types/Product';

/**
 * Returns a list of group refrences from types that include products / are products
 */
function getGroupReferences(items: (Product | ProductSmall | Look)[]): string[] {
  return Array.from(
    new Set(
      items.flatMap(v => {
        if ('products' in v) {
          return getGroupReferences(v.products.map(({ product }) => product));
        }
        return v.groupReference;
      }),
    ),
  );
}

export default getGroupReferences;
