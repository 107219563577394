/* eslint-disable @typescript-eslint/no-explicit-any */

import { Look } from 'types/Look';
import { ScheduledRelease } from 'types/ScheduledRelease';
import { sanityClient } from '../client';
import type { GetProductFeedOptions } from '../getProductFeed';
import {
  PUBLISHED_PRODUCTS_CONDITION,
  PUBLISHED_PROMOTED_SCHEDULED_RELEASES,
  RELEASED_CONDITION,
} from '../utils';
import elementToProductSmall from './helper/elementsToProducts';

export default async function getPreviousScheduledReleases(
  options: GetProductFeedOptions,
): Promise<ScheduledRelease[]> {
  try {
    // A lot of any here, but it's we don't know the types either
    const data = await sanityClient.fetch<
      {
        elements: unknown[];
        releaseAt: string;
      }[]
    >(
      `*[
        _type == "scheduledRelease" &&
        ${RELEASED_CONDITION} &&
        ${PUBLISHED_PROMOTED_SCHEDULED_RELEASES}
      ] | order(dateTime(releaseAt) desc) [$offset..($offset + ($limit - 1))]{
        releaseAt,
        elements[${PUBLISHED_PRODUCTS_CONDITION}]->{
          ...,
          brand->,
          products[]{..., product->{..., brand->,"videos": media[]->{...}}},
        }
      }`,
      options,
    );

    return data.map(
      (release): ScheduledRelease => ({
        items: release.elements
          .filter(element => element != null)
          .map((element: any) => {
            if (element._type === 'product') {
              return elementToProductSmall(element);
            }

            const look: Look = {
              image: element.image,
              name: element.name,
              products: element.products
                .map((point: any) => {
                  const product = elementToProductSmall(point.product);

                  if (!product) {
                    return undefined;
                  }

                  return {
                    product: product,
                    x: point.x / 100,
                    y: point.y / 100,
                  };
                })
                .filter(
                  (point: unknown): point is NonNullable<typeof point> =>
                    typeof point !== 'undefined',
                ),
            };

            return look;
          })
          .filter((v): v is NonNullable<typeof v> => v != null),
        releaseAt: release.releaseAt,
      }),
    );
  } catch (e) {
    return [];
  }
}
