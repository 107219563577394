import { BrandSmall } from 'types/Brand';
import { sanityClient } from '../client';

export default async function getBrands(): Promise<BrandSmall[]> {
  try {
    const data = await sanityClient.fetch<{ hidden?: boolean; name: string; slug: string }[]>(
      `*[_type == "brand" && hidden != true] { name, "slug": slug.current, hidden }`,
    );

    return data.sort((a, b) => a.name.localeCompare(b.name)).map(v => ({ hidden: false, ...v }));
  } catch {
    return [];
  }
}
