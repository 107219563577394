import React, { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CURRENCY } from 'consts';

import Button from 'components/ui/Button';
import Spinner from 'components/ui/Spinner';
import Typography from 'components/ui/Typography';
import { useCart } from 'contexts/CartContext';
import { useCookieAcceptState } from 'contexts/CookieContext';
import gtag from 'lib/gtag';
import { useCartVerification } from 'queries';
import { cx, getBananasCommerce, productToGtagItem } from 'utils';
import {
  useCheckoutFormValues,
  useCheckoutPage,
  useCheckoutShippingPrice,
  useCheckoutShippingReference,
} from '../../CheckoutContext';
import { ingridReplaceScriptNode } from './helpers';
import classes from './Ingrid.module.css';

/*
 Caution future developers. This component is not allowed to unmount after being mounted.
 Yes, that's just how ingrid works. The docs recommends using jQuery so it's a bit dated.
 Just keep that in mind when reading this code and using this component.
 I've tried to move all the logic to this component so you're don't have to look
 at the horrific implementation

 throughout this component i'm trying to explain how everything works.
 */

const IngridPage: React.FC = () => {
  const {
    cartDrawerShown: [cartDrawerShown],
    products,
  } = useCart();
  const bcom = getBananasCommerce();

  const [, setPage] = useCheckoutPage();

  const [, setPickupLocationName] = React.useState<string>();
  const [shippingPrice, setShippingPrice] = useCheckoutShippingPrice();
  const [, setShippingReference] = useCheckoutShippingReference();

  const cartVerification = useCartVerification({
    references: products.map(v => v.item.reference),
  });

  // The ref to the shipping widget
  const shippingWidgetRef = React.useRef<HTMLDivElement>(null);

  // This query updates and initiates the shipping ref
  // And returns a shippingReference
  const shippingReference = useQuery({
    cacheTime: 0,
    enabled: cartDrawerShown && !cartVerification.isLoading && !cartVerification.isError,
    queryFn: async ({ signal }) => {
      // This function is gonna behave differently depending on
      // If the shippingWidget has already been mounted or if this is the first time
      // The update and create looks pretty similar so i keep them both in this function

      // When starting an ingrid session
      // We'll get a html snippet containing a script tag which includes the ingrid api
      // Therefore we don't have access to ingrid functions before the widget has been mounted

      // When updating the ingrid session you need to pause the api
      // However this only applies after creation

      // If there are no products in the cart, don't do anything
      // This works because the CheckoutContent component will be showing a 'Din varukorg är tom' message
      // So this page will look completely broken, but it's hidden behind a modal
      // And again, this is because the ingrid widget is not allowed to unmount
      if (!products.length) {
        return 'anything';
      }

      // If the widget already exists suspend it (This should only be done when updating session data, not user data)
      window._sw?.(api => {
        api.suspend();
      });

      const result = await bcom.ingrid.begin({
        cart: {
          items: products.map(p => ({ quantity: 1, reference: p.item.reference })),
        },
      });

      // If a new query has started while this query was loading
      const aborted = Boolean(signal?.aborted);
      if (aborted) {
        return 'anything';
      }

      if (result._type !== 'created' && result._type !== 'updated') {
        // TODO Something went wrong, handle this in the future
        throw result;
      }

      // Initiate the widget if window._sw is not defined
      // This should mean that the widget is empty and the script needs to be ran
      // If not something has gone really wrong, so we'll catch that early
      // That's why i check for window._sw and not for if the shippingWidgetRef has 0 children
      if (!window._sw && shippingWidgetRef.current) {
        shippingWidgetRef.current.innerHTML = result.shippingData;

        // Snippet from ingrid docs
        // This is a trick to get the script tag to run
        const scriptNode = document.getElementById('shipwallet-container');
        if (scriptNode instanceof Element) {
          ingridReplaceScriptNode(scriptNode);
        } else {
          console.warn('Ingrid: something went wrong; unable to locate injected HTML.');
        }
      }

      if (window._sw) {
        window._sw(api => api.resume());
      }

      return result.shipping.reference;
    },
    queryKey: [
      'ingrid-shipping-reference',
      { references: products.map(p => p.item.reference).sort() },
    ],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: Infinity,
  });

  const [, setFormValues] = useCheckoutFormValues();

  // This is because multiple listeners is probably not expected behavior
  const hasInitiatedApiListeners = useRef(false);
  useEffect(() => {
    if (shippingReference.data && !hasInitiatedApiListeners.current) {
      setShippingReference(shippingReference.data);
      if (window._sw) {
        window._sw(api => {
          api.on('data_changed', data => {
            setPickupLocationName(data.pickup_location?.name);
            setShippingPrice(data.price);

            setFormValues(formValues => {
              const customer = formValues?.customer ?? {
                city: '',
                countryCode: 'SE',
                email: '',
                familyName: '',
                givenName: '',
                postalCode: '',
                streetAddress: '',
              };

              return {
                customer: {
                  ...customer,
                  postalCode: data.search_address?.postal_code ?? customer.postalCode,
                },
              };
            });
          });
        });

        hasInitiatedApiListeners.current = true;
      } else {
        console.warn("Couldn't initiate ingrid listener");
      }
    }
  }, [setFormValues, setShippingPrice, setShippingReference, shippingReference.data]);

  const cookieAccept = useCookieAcceptState();

  return (
    <form autoComplete="off" className={classes.wrapper}>
      <div
        className={cx(
          (shippingReference.isLoading || cartVerification.isLoading) &&
            classes.ingridFadedInformation,
          classes.hide,
        )}
      >
        <Spinner />
      </div>
      <div>
        <div
          className={cx(
            (shippingReference.isLoading || shippingReference.isError) && classes.ingridWidgetFaded,
          )}
          ref={shippingWidgetRef}
        />
      </div>
      {!shippingReference.isLoading &&
        (() => {
          if (!shippingReference.isError) {
            return (
              <Button
                disabled={shippingPrice == null || shippingReference.isLoading}
                onClick={() => {
                  if (cookieAccept.analytics) {
                    gtag('event', 'add_shipping_info', {
                      currency: CURRENCY,
                      items: products.map(productToGtagItem),
                      value: products.reduce((a, v) => a + v.item.price, 0),
                    });
                  }

                  setPage('form');
                }}
                className={classes.button}
                size="full"
              >
                Fortsätt till kontakt & leveransadress
              </Button>
            );
          }

          return (
            <div className={classes.spinnerContainer}>
              <Typography>Någonting gick fel</Typography>
            </div>
          );
        })()}
    </form>
  );
};

export default IngridPage;
