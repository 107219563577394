import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { MODAL_ROOT_ID } from 'consts';

import useLockedBody from 'hooks/useLockedBody';
import generateId from 'utils/generateId';

export type ModalProps = {
  children: (options: { id: string; open: boolean }) => React.ReactNode;
  id?: string;
  open: boolean;
};

const Modal: React.FC<ModalProps> = props => {
  // This id is used to designate the scrollable component
  // On iOS, set this id to the id of the element you want
  // to be scrollable when the modal opens
  const id = useRef(props.id ?? generateId(128)).current;
  const [, setLocked] = useLockedBody(props.open, id);

  useEffect(() => {
    setLocked(props.open);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  return mounted
    ? ReactDOM.createPortal(
        <div>{props.children({ id, open: props.open })}</div>,
        document.getElementById(MODAL_ROOT_ID) as HTMLDivElement,
      )
    : null;
};

export default Modal;
