import { Page } from 'types/Page';
import { sanityClient } from '../client';

export default async function getStaticPage(slug: string) {
  try {
    const data = await sanityClient.fetch<Page>(`*[_type == "page" && slug.current == $slug][0]`, {
      slug,
    });

    return data;
  } catch {
    return null;
  }
}
