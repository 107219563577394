import React from 'react';
import cx from 'classnames';

import styles from './Container.module.css';

export interface ContainerProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  component?: React.ElementType;
  desktop?: boolean;
  fullHeight?: boolean;
  padding?: 'none' | 'small' | 'tight' | 'contained-regular' | 'regular';
  variant?: undefined | 'full';
}

/**
 * Contained content, adding a max-width and vertical padding.
 * Should be used in conjunction with <Block/> to create layouts.
 * */
const Container: React.FC<ContainerProps> = ({
  children,
  className,
  fullHeight = false,
  padding = 'regular',
  variant,
  ...rest
}) => {
  return (
    <div
      className={cx(
        styles.root,
        {
          [styles.full]: variant === 'full',
          [styles.fullHeight]: fullHeight,
          [styles[`padding-regular`]]: padding === 'regular',
          [styles[`padding-contained-regular`]]: padding === 'contained-regular',
          [styles[`padding-tight`]]: padding === 'tight',
          [styles[`padding-small`]]: padding === 'small',
        },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Container;
