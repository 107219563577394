import React, { useEffect, useRef } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import Script from 'next/script';
import NextNProgress from 'nextjs-progressbar';

import CookiePopup from 'components/partials/CookiePopup';
import Layout from 'components/partials/Layout';
import { CartContextProvider } from 'contexts/CartContext';
import CookieProvider from 'contexts/CookieContext';
import SideMenuProvider from 'contexts/SideMenu';
import UserProvider from 'contexts/UserContext';
import { useScrollRestore } from 'hooks';
import gtag from 'lib/gtag';
import { AppLayoutProps } from 'types/AppLayoutProps';

import 'swiper-config';
import 'styles/global.css';

const queryClient = new QueryClient();

function App<T extends AppLayoutProps>({ Component, pageProps }: AppProps<T>) {
  const router = useRouter();
  const {
    publicRuntimeConfig: {
      git,
      google: { tagManagerId },
    },
  } = getConfig();
  useScrollRestore();

  // This handles the back button on the product page
  useEffect(() => {
    const url = new URL(window.location.href);

    if (url.searchParams.has('back')) {
      router.replace(
        {
          query: { ...router.query, back: undefined },
        },
        undefined,
        { shallow: true },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  }, []);
  useEffect(() => {
    window.gitHash = git.hash;
  }, [git.hash]);

  // beautiful use state list
  const [acceptCookie, setAcceptCookie] = React.useState(false);
  const [acceptAnalytics, setAcceptAnalytics] = React.useState(false);
  const [acceptMarketing, setAcceptMarketing] = React.useState(false);

  const skipFirstPageViewEvent = useRef(true);
  useEffect(() => {
    const handleRouteComplete = (url: string) => {
      if (!skipFirstPageViewEvent.current && acceptAnalytics) {
        gtag('event', 'page_view', {
          page_location: url,
          page_title: document.title,
        });
      } else if (acceptAnalytics) {
        skipFirstPageViewEvent.current = false;
      }
    };

    router.events.on('routeChangeComplete', handleRouteComplete);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteComplete);
    };
  }, [acceptAnalytics, router.events]);

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <CookieProvider
          accepted={acceptCookie}
          analytics={acceptAnalytics}
          marketing={acceptMarketing}
        >
          <CartContextProvider>
            <SideMenuProvider>
              <NextNProgress options={{ showSpinner: false }} color="#000" />
              {/*
                  The layout modal is placed here instead of placing it on
                  the page components. It's here because
                  it shouldn't be remounted on every page load. That causes
                  weird behavior.
                */}
              <Layout
                showWelcomeBanner={pageProps.showWelcomeBanner}
                nextRelease={undefined}
                navbarMenu={pageProps.navbarMenu}
                footerMenu={pageProps.footerMenu}
                navbarHeaderColor={pageProps.navbarHeaderColor}
              >
                <Component {...pageProps} />
              </Layout>
              {acceptAnalytics && (
                <Script
                  id="google-tag-manager"
                  type="text/javascript"
                  strategy="afterInteractive"
                  src={`https://www.googletagmanager.com/gtm.js?id=${tagManagerId}`}
                />
              )}
              <CookiePopup
                version="1"
                categories={[
                  { id: 'marketing', title: 'Marknadsförings-cookies' },
                  { id: 'analysis', title: 'Analys-cookies' },
                ]}
                onAccept={data => {
                  setAcceptCookie(true);
                  setAcceptAnalytics(data.analysis);
                  setAcceptMarketing(data.marketing);

                  // Initialize Tag Manager.
                  if (data.analysis) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime() });
                  }
                }}
              />
            </SideMenuProvider>
          </CartContextProvider>
        </CookieProvider>
      </UserProvider>
    </QueryClientProvider>
  );
}

export const getInitialProps = async () => {
  return {};
};
export default App;
